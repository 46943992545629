.btn .svg-icon {
  margin-left: .45rem;
  height: 1.125rem;
  width: 1.125rem;
  margin-top: -2px;
}
.mobile-check {
  display: block;
  @media (max-width: 1199.98px) and (orientation: landscape) {
    display: none;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.h-100-calc {
  &.show {
    height: calc(100% - 3rem);
    @media (max-width: 1199.98px) and (orientation: landscape) {
      height: auto;
    }
    @include media-breakpoint-down(md) {
      height: auto;
    }
  }
}

.accordion.horizontal {

  .collapsing {
    visibility: visible;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
  }

  .collapsing.width {
    -webkit-transition-property: width, visibility;
    transition-property: width, visibility;
    width: 0;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    z-index: 0;
  }

  .collapse {
    width: auto;
  }

  .collapse.show {
    visibility: visible;
  }

  .collapse.show.width {
    width: auto;
  }
  .description {
    a {
      color: #00ADD8 !important;
      &:focus,
      &:hover {
        color: $cyan !important;
      }
    }
  }

  .image-wrap {
    height: calc(100% - 1rem);
  }

/*  .swiper-container {
    height: calc(100% - 1rem);
  }*/
  .text-white,
  .text-light {
    color: #FFFFFF !important;

    h1, h2, h3, h4, h5, h6,
    .h1 .h2, .h3, .h4, .h5, .h6 {
      color: #ffffff !important;
    }
  }
  .text-darker,
  .text-dark {
    color: #434C53 !important;
    h1, h2, h3,
    .h1 .h2, .h3 {
        /*color: #002D72 !important;*/
        /*color: #ffffff !important;*/
    }
    h4, h5, h6,
    .h4, .h5, .h6 {
      color: $gray-900 !important;
    }
  }

  .text-dark-on-white {
    color: $gray-900 !important;

    h1, h2, h3,
    .h1 .h2, .h3 {
      color: #002D72 !important;
    }

    h4, h5,
    .h4, .h5 {
      color: #434C53 !important;
    }

    h6, .h6 {
      color: #B0B6B8 !important;
    }
  }


  .dotted-spaced {
    background-image: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 9px 2px;
    background-repeat: repeat-x;
  }

  .detail-link {
    background-color: rgba(255,255,255,.15);
    border-color: rgba(255,255,255,0);
    color: $white;
    &:focus,
    &:hover {
      background-color: rgba(255,255,255,0);
      border-top-color: rgba(255,255,255,1);
      border-bottom-color: rgba(255,255,255,1);
    }
    &.text-darker,
    &.text-dark {
      background-color: rgba(0,0,0,.15);
      border-color: rgba(0,0,0,0);
      color: $gray-900 !important;
      &:focus,
      &:hover {
        background-color: rgba(0,0,0,0);
        border-top-color: $gray-900;
        border-bottom-color: $gray-900;
      }
    }
    &.text-dark-on-white {
        background-color: rgba(237,241,242,1);
        border-color: rgba(237,241,242,0);
        color: $gray-900 !important;
        &:focus,
        &:hover {
          background-color: rgba(237,241,242,0);
          border-top-color: $gray-900;
          border-bottom-color: $gray-900;
        }
      }
  }
  min-height: 730px;
  @media (max-width: 1199.98px) and (orientation: landscape) {
    height: auto;
    width: 100%;
    min-height: 100vh;
  }
  @include media-breakpoint-down(md) {
    height: auto;
    width: 100%;
    min-height: 100vh;
  }
  .focuspoint img {
    position: absolute;
  }
  .svg-icon {
    &.open-close {
      width: 3.125rem;
      height: 3.125rem;
      --color1: #ffffff;
      --color2: transparent;
      --color3: rgba(255, 255, 255, .15);

      .closesymbol {
        display: none;
      }
    }

    &:hover {
      &.open-close {
        --color2: #ffffff;
        --color3: rgba(255, 255, 255, 0);
      }
    }
  }
  .button-light {
    .svg-icon {
      &.open-close {
        width: 3.125rem;
        height: 3.125rem;
        --color1: #ffffff;
        --color2: transparent;
        --color3: rgba(255, 255, 255, .15);

        .closesymbol {
          display: none;
        }
      }

      &:hover {
        &.open-close {
          --color2: #ffffff;
          --color3: rgba(255, 255, 255, 0);
        }
      }
    }
  }
  .button-dark-on-white,
  .button-darker,
  .button-dark {
    .svg-icon {
      &.open-close {
        width: 3.125rem;
        height: 3.125rem;
        --color1: #333;
        --color2: transparent;
        --color3: rgba(51,51,51,.15);
        .closesymbol {
          display: none;
        }
      }
      &:hover {
        &.open-close {
          --color2: #333;
          --color3: rgba(51,51,51,0);
        }
      }
    }
  }
  .card {
    border: 0;
    &.active {
      width: auto !important;
    }
    .card-border {
      position: absolute;
      width: 2px;
      bottom: 0;
      top: 3rem;
      right: 0;
      background-image: linear-gradient(to bottom, #fff 10%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 2px 9px;
      background-repeat: repeat-y;
      padding-top: 0;
      /*margin-top: 1.25rem;*/
      @media (max-width: 1199.98px) and (orientation: landscape) {
        background-image: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom left;
        background-size: 9px 2px;
        background-repeat: repeat-x;
        top: auto;
        /*padding-left: 0;*/
        left: 1.25rem;
        margin-top: 0;
        right: 0;
        height: 2px;
        width: auto;
        bottom: 0;
      }
      @include media-breakpoint-down(md) {
        background-image: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom left;
        background-size: 9px 2px;
        background-repeat: repeat-x;
        top: auto;
        /*padding-left: 0;*/
        left: 1.25rem;
        margin-top: 0;
        right: 0;
        height: 2px;
        width: auto;
        bottom: 0;
      }
    }
    &:last-child {
      .card-border {
        display: none;
      }
    }
    .card-header {
      .title {
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
        margin-top: -0.625rem;
      }
      cursor: pointer;
      @media (max-width: 1199.98px) and (orientation: landscape) {
        padding-right: 3.5rem;
        &.bordered {
          border: 0;
        }
        &.transparent {
          background-color: transparent !important;
        }
        .title {
          font-size: 2rem;
        }
      }
      @include media-breakpoint-down(md) {
        padding-right: 3.5rem;
        &.bordered {
          border: 0;
        }
        &.transparent {
          background-color: transparent !important;
        }
        .title {
          font-size: 2rem;
        }
      }
    }

    &.text-light {
      .swiper-pagination-bullet-active {
        background: white;
      }
    }
    &.text-dark-on-white,
    &.text-darker,
    &.text-dark {
      .card-border:not(.white-1) {
        background-image: linear-gradient(to bottom, #000 10%, rgba(255, 255, 255, 0) 0%);
        background-position: right;
        background-size: 2px 9px;
        background-repeat: repeat-y;
        @media (max-width: 1199.98px) and (orientation: landscape) {
          background-image: linear-gradient(to right, #000 10%, rgba(255, 255, 255, 0) 0%);
          background-position: bottom left;
          background-size: 9px 2px;
          background-repeat: repeat-x;
        }
        @include media-breakpoint-down(md) {
          background-image: linear-gradient(to right, #000 10%, rgba(255, 255, 255, 0) 0%);
          background-position: bottom left;
          background-size: 9px 2px;
          background-repeat: repeat-x;
        }
      }
    }

    .card-body {
      /*min-height: 100%;*/
      width: calc(100% - 2.5rem);
      @media (max-width: 1199.98px) and (orientation: landscape) {
        transform: scale(1) !important;
        width: 100%;
        transition-property: height,scale;
        &.bordered {
          border: 0;
          margin-top: 0;
        }
      }
      @include media-breakpoint-down(md) {
        transform: scale(1) !important;
        width: 100%;
        transition-property: height,scale;
        &.bordered {
          border: 0;
          margin-top: 0;
        }
      }
    }

    .init {
      .card-header {
        .title {
          transform: rotate(0deg);
          writing-mode: horizontal-tb;
          width: auto !important;
        }
      }
    }
    &:last-child .bordered {
        border-right: none;
        background: none;
    }

  }

  &.width {
    display: flex;
    @media (max-width: 1199.98px) and (orientation: landscape) {
      flex-direction: column;
      height: auto;
      width: 100%;
      .card {
        /* border-bottom: 1px dotted white; */
        /*
        background-image: linear-gradient(to bottom, #fff 10%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 1px 10px;
        background-repeat: repeat-y;
         */

        width: 100vw !important;
        max-font-size: 100vw;
        height: auto;
      }
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      height: auto;
      width: 100%;
      .card {
        /* border-bottom: 1px dotted white; */
        /*
        background-image: linear-gradient(to bottom, #fff 10%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 1px 10px;
        background-repeat: repeat-y;
         */

        width: 100vw !important;
        max-font-size: 100vw;
        height: auto;
      }
    }
    .card {
        flex-direction: row;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: min-content;
        hyphens: auto;
        @media (max-width: 1199.98px) and (orientation: landscape) {
          min-width: 100%;
          max-width: 100%;
        }
        @include media-breakpoint-down(md) {
          min-width: 100%;
          max-width: 100%;
        }
        flex-wrap: wrap;
        width: 1%;
        .card-header {
          cursor: pointer;
          .title {
            max-height: 560px;
            text-align: right;
            width: auto;
            min-width: 80px;
            transform: rotate(-180deg);
            writing-mode: vertical-rl;
            margin-top: -0.625rem;
            @media (max-width: 1199.98px) and (orientation: landscape) {
              text-align: left;
              transform: rotate(0deg);
              writing-mode: horizontal-tb;
            }
            @include media-breakpoint-down(md) {
              text-align: left;
              transform: rotate(0deg);
              writing-mode: horizontal-tb;
            }
          }
        }
        .init,
        &.active {
          .card-header {
            .title {
              text-align: left;
              transform: rotate(0deg);
              writing-mode: horizontal-tb;
            }
          }
        }
        &.active {
          .card-header {
            &.d-none-active {
              display: none;
              @media (max-width: 1199.98px) and (orientation: landscape) {
                display: block;
              }
              @include media-breakpoint-down(md) {
                display: block;
              }
            }
          }
        }
        &.transparent {
          background-color: transparent !important;
        }
      }
  }
  .open-slide {
    position: absolute;
    bottom: 2.125rem;
    right: 2.125rem;
    z-index: 2;
    transition: all 0.35s linear;
  }

  .card-header {
    background-color: unset;
    padding: 1.25rem;
    height: 100%;
    border-bottom: unset;
  }
  .collapse {
    /*margin-left: 50%;*/
  }
  .collapse.show {
    margin-left: 0;
  }
  .collapsing {
    @media (max-width: 1199.98px) and (orientation: landscape) {
      width: 100%;
      transition-property: height,scale;
      .card-body {
        height: 100%;
        transform: scaleY(0);
      }
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      transition-property: height,scale;
      .card-body {
        height: 100%;
        transform: scaleY(0);
      }
    }
  }
  .collapse.show + .open-slide .plussymbol {
    display: none;
  }
  .collapse.show + .open-slide .closesymbol {
    display: inline-block;
  }
  .accordion-background {
    position: absolute;
    opacity: 0;
    transition: all .35s linear;
    transform-origin: center center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .accordion-background.active {
    opacity: 1;
    transform: scale(1);
  }

  .accordion-background-overlay {
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
  }
  .carousel .carousel-control-prev,
  .carousel .carousel-control-next {
    visibility: hidden;
  }
  .carousel:hover .carousel-control-prev,
  .carousel:hover .carousel-control-next {
    visibility: visible;
  }

  @media (max-width: 1199.98px) and (orientation: landscape) {
    .collapsed.show .card-body {
      height: 100%;
      transform: scaleY(1);
    }

    .collapse {
      width: 100%;
    }


    .open-slide {
      bottom: auto;
      top: 1.4rem;
      right: 1rem;
    }
    .collapsing + .open-slide,
    .collapse.show + .open-slide {
      position: relative !important;
      width: 100%;
      text-align: right;
      bottom: 1.4rem;
      top: auto;
    }
  }
  @include media-breakpoint-down(md) {
    .collapsed.show .card-body {
      height: 100%;
      transform: scaleY(1);
    }

    .collapse {
      width: 100%;
    }


    .open-slide {
      bottom: auto;
      top: 1.4rem;
      right: 1rem;
    }
    .collapsing + .open-slide,
    .collapse.show + .open-slide {
      position: relative !important;
      width: 100%;
      text-align: right;
      bottom: 1.4rem;
      top: auto;
    }
  }

  .swiper-wrap {
    position: relative;
  }
}

.v2 {
  .accordion.horizontal {
    @media (max-width: 1199.98px) and (orientation: landscape) {
      .open-slide {
        bottom: 1.4rem;
        top: auto;
        right: 1rem;
      }
    }
    @include media-breakpoint-down(md) {
      .open-slide {
        bottom: 1.4rem;
        top: auto;
        right: 1rem;
      }
    }
  }
}
