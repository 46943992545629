.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: block;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s ease, visibility 0s 0.4s ease;
  background: $black;
  &.show {
    bottom: 0;
    opacity: .6;
    visibility: visible;
    z-index: 2;
    transition: opacity .4s ease;
    @include media-breakpoint-down(sm) {
      opacity: .5;
    }
  }
}

.loaderwrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  display: none;
  transition: none;
  z-index: 1100;
  opacity: 0;
  transition: opacity .4s ease;
  &:before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: "";
    background: $overlay-background;
    opacity: .7;
  }
  &.show {
    display: block !important;
    opacity: 1;
    transition: opacity .4s ease;
  }
  .spinner {
    position: absolute;
    top: 50%;
    width: $spinner-width;
    height: $spinner-height;
    color: $secondary;
    opacity: 1;
    margin-left: -$spinner-width/2;
  }
}
