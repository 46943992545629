footer {
  .nav-item {
    position: relative;
    .nav-link {
      font-size: $font-size-base * 1.11;
      font-weight: $font-weight-light;
    }
    &:after {
      content: "|";
      position: absolute;
      right: 0;
      top: 0;
      padding: $nav-link-padding-y 0;
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}

.v2 {
  footer {
    .footer-text {
      font-size: 1.25rem;
      @include media-breakpoint-down(md) {
        font-size: 1.45rem;
      }
    }
    .svg-icon {
      height: 3.14rem;
      width: 3.14rem;
      @include media-breakpoint-down(md) {
        height: 3.52rem;
        width: 3.52rem;
      }
    }
    .social {
      .nav-item:after {
        display: none;
      }
    }
    .footer-header {
      color: $white;
      font-size: 35px;
      @include media-breakpoint-down(sm) {
        margin-right: 3.4375rem;
      }
      .svg-icon {
        margin-right: 1rem;
        width: 40px;
        height: 40px;
      }
    }
    .t3mailtoui,
    .footer-question,
    .footer-link,
    .nav-link {
      color: $white;
      &:hover {
        color: $cyan;
        text-decoration: none;
      }
    }
    .footer-question {
      font-size: 18px !important;
    }
    @include media-breakpoint-down(md) {
      .footer-link {
        font-size: 1.45rem !important;
      }
    }
    .footer-bar {
      .nav-link {
        font-family: $font-family-light;
        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }
      @include media-breakpoint-down(md) {
        ul {
          line-height: 1.85rem;
          .nav-item {
            &:after {
              content: none;
            }
          }
        }
      }
    }
  }
}
