.tx_solr {
  .results-list {
    .results-entry {
      .results-topic {
        a {
          color: $blue;
        }
      }
      .results-teaser {
        color: $gray-900;
        .results-highlight {
          color: $blue;
          font-weight: bold;
        }
      }
      &:hover {
        a {
          color: $cyan;
          text-decoration: none;
          .results-topic {
            color: $cyan !important;
          }
        }
      }
    }
  }

  .tx-solr-autosuggest {
    background: white;
    box-shadow: 0px 0px 20px #00000029;
    padding-bottom: 30px;
    padding-top: 50px;
    top: 0;
    .autocomplete-group,
    .autocomplete-suggestion {
      border-bottom: 1px solid #6D7579;
      padding-top: 9.5px;
      padding-bottom: 9.5px;
      margin-left: 20px;
      margin-right: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }

      a {
        color: $gray-900;;
        &:hover {
          color: $cyan;
          text-decoration: none;
        }
      }

    }
    .autocomplete-group {
      font-family: $font-family-bold;
      border-bottom: 0;
      padding-bottom: 0;
    }

    .autocomplete-suggestion {
      &:hover {
        color: $cyan;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  .tx-solr-search-form-top {
    .tx-solr-autosuggest {
      overflow: auto;
    }
  }
}

.tx-solr-autocomplete {
  flex: 1 1 auto;
  &.autocomplete-active {
    .form-control {
      border: 0 !important;
      z-index: 10000;
    }
  }
  .form-control {
    height: 100%;
    @include media-breakpoint-down(sm) {
      border-color: $gray-400 !important;
    }
  }
}

.solr-pagination {
  ul {
    flex-wrap: wrap;

    li {
      margin-bottom: 0 !important;

      .btn {
        padding: 0.5rem;
        width: 2.25rem;
        height: 2.25rem;
        margin-left: 0.3125rem;
        margin-right: 0.3125rem;
        &:hover {
          border: 1px solid #434c53;
        }
      }
    }
    .previous,
    .next {
      a {
        padding: .5rem;
        line-height: 1;
        display: inline-block;
        color: $gray-900;
        .svg-icon {
          color: $gray-900;
          --color1: $gray-900;
        }
        &:hover {
          color: $cyan;
          text-decoration: none;
          .svg-icon {
            color: $cyan;
            --color1: $cyan;
          }
        }
      }
    }
    .next {
      margin-left: 15px;
    }
    .previous {
      margin-right: 15px;
    }
  }
}
