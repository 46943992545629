.fancybox-container {
  &.fancybox-show-thumbs {
    .fancybox-inner {
      right: 0 !important;
    }
  }
  .fancybox-inner {
    .fancybox-thumbs {
      bottom: 0;
      top: auto;
      width: 100%;
      background: none;
      &__list{
        margin: 0 auto;
        a {
          background: none !important;
          height: 3px;
          width: 20px;
          padding: 22px 0;
          display: inline-flex;
          &:before {
            background-color: $white;
            opacity: 0.15;
            border: 0;
            height: 3px;
            width: 100%;
            position: relative;
            display: inline-block;
          }
          &.fancybox-thumbs-active {
            &:before {
              background-color: $white;
              opacity: 1;
            }
          }
          @include media-breakpoint-up(lg) {
            &:hover {
              &:before {
                background-color: $cyan;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .fancybox-close-small {
    top: 10px;
    right: 10px;
    .svg-icon {
      color: $white;
      --color1: #FFF;
    }
  }
  .fancybox-navigation {
    .fancybox-button {
      background: none !important;
      color: $white;
      visibility: visible;
      opacity: 1;
      .svg-icon {
        color: $white;
        --color1: #FFF;
      }
      &:disabled {
        opacity: 0;
        .svg-icon {
          color: $white !important;
          --color1: #FFF !important;
        }
      }
      &:hover {
        .svg-icon {
          color: $cyan;
          --color1: #00add8;
        }
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
