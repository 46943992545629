.tx-storen-fabricsamples {
  .sticky-top {
    z-index: 1;
  }
  .fabricsamples-list {
    .fabricsamples-item {
      .btn.cart {
        @include media-breakpoint-down(md) {
          padding: 0.5rem !important;
          border: 0 !important;
        }
      }
    }
  }
}

.cart-item {
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}
.overflow {
  padding-right: 2.5rem;
}
.modal.filter form {
  margin-right: 0.5rem;
  @include media-breakpoint-down(md) {
    margin-right: 1rem;
  }
  display: block;
  overflow: hidden;
  .custom-control-label {
    word-break: break-word;
  }
}
.detail {
  .button-light {
    outline: none;
    position: absolute;
    width: 3.13rem;
    height: 3.13rem;
    @include media-breakpoint-down(md) {
      width: 2.93rem;
      height: 2.93rem;
    }
    --color1: #ffffff;
    --color2: transparent;
    --color3: rgba(255, 255, 255, .15);
    right: 10px;
    left: auto;
    top: 10px;
    .svg-icon {
      width: 3.13rem;
      height: 3.13rem;
      @include media-breakpoint-down(md) {
        width: 2.93rem;
        height: 2.93rem;
      }
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  &:hover {
    .button-light {
      --color2: #ffffff;
      --color3: rgba(255, 255, 255, 0);
    }
  }
}

.js-reset-filter {
  @include media-breakpoint-down(md) {
    padding: .935rem 0 .935rem 0 !important;
  }
  .svg-icon {
    margin-right: -10px;
  }
}
.fabricsamples-item {
  a {
    .button-light {
      width: 2.25rem;
      height: 2.25rem;
      --color1: #ffffff;
      --color2: transparent;
      --color3: rgba(255, 255, 255, .15);
      right: 10px;
      bottom: 10px;
      left: auto;
      top: auto;
      .svg-icon {
        width: 3.13rem;
        height: 3.13rem;
        @include media-breakpoint-down(md) {
          width: 2.93rem;
          height: 2.93rem;
        }
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    &:hover {
      .button-light {
        --color2: #ffffff;
        --color3: rgba(255, 255, 255, 0);
      }
    }
  }
}
.filter-group-title {
  text-align: center;
  span {
    position: relative;
    &:after,
    &:before {
      content: "";
      position: absolute;
      height: 1px;
      background: $modal-border;
      width: 600px;
      top: 50%;
    }
    &:before {
      right: 100%;
      margin-right: 15px;
    }
    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.filter-info {
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    margin-top: 0;
  }
}
.reset-fabricsamples-form {
  @include media-breakpoint-down(md) {
    width: 100%;
    order: -1;
    margin-bottom: 1rem;
    .svg-icon {
      margin-left: -.5rem;
    }
  }
}
