//version2 phase 2
//responsive grid
.sticky-detect {
  transition: all .2s ease-in-out;
  background: $white;
  opacity: .9;
  z-index: 1020;
  &.is_stuck {
    position: sticky;
    top: 0;
  }
}

.main-header + .main-container .sticky-detect.is_stuck {
  top: 60px !important;
  @include media-breakpoint-down(md) {
    top: 35px !important;
  }
}
.main-header.only-mainnav + .main-container .sticky-detect.is_stuck {
  top: 0 !important;
}

.v2 {
  padding: 0 40px;
  @media (max-width: 767.98px) and (orientation: landscape) {
    padding: 0 30px;
  }
  @media (max-width: 767.98px) and (orientation: portrait) {
    padding: 0 20px;
  }
  .container {
    @each $breakpoint, $gutter in $grid-gutter-widths {
      @include media-breakpoint-up($breakpoint) {
        @include make-container($gutter);
      }
    }
  }
  .container-fluid {
    @each $breakpoint, $gutter in $grid-gutter-widths {
      @include media-breakpoint-up($breakpoint) {
        @include make-container($gutter);
      }
    }
  }
  .logo {
    @include media-breakpoint-down(md) {
      left: 0 !important;
      max-width: 101px!important;
      right: auto;
    }
    right: 11.11rem !important;
  }
  .container-narrow-light,
  .container-narrow-dark,
  .container-extended-light,
  .container-extended-dark,
  .container-extended,
  .container-narrow {
    > .content-fluid {
      padding: 0;
    }
    > header {
      padding: 0;
    }
  }

  .row {
    @each $breakpoint, $gutter in $grid-gutter-widths {
      @include media-breakpoint-up($breakpoint) {
        @include make-row($gutter);
      }
    }
  }
  .row > .col,
  .row > [class*='col-'] {
    @each $breakpoint, $gutter in $grid-gutter-widths {
      @include media-breakpoint-up($breakpoint) {
        @include make-col-paddings($gutter);
      }
    }
  }

  .bg-container,
  .main-footer-wrap,
  .container-narrow-dark,
  .container-narrow-light,
  .container-extended-light,
  .container-extended-dark,
  .container-full {
    margin-left: -40px;
    margin-right: -40px;
    @media (max-width: 767.98px) and (orientation: landscape) {
      margin-left: -30px;
      margin-right: -30px;
    }
    @media (max-width: 767.98px) and (orientation: portrait) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .main-header {
    margin-left: -40px;
    margin-right: -40px;
    @media (max-width: 767.98px) and (orientation: landscape) {
      margin-left: -30px;
      margin-right: -30px;
    }
    @media (max-width: 767.98px) and (orientation: portrait) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .bg-container,
  .container-narrow-light,
  .container-narrow-dark,
  .container-extended-light,
  .container-extended-dark {
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: 767.98px) and (orientation: landscape) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (max-width: 767.98px) and (orientation: portrait) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .main-footer-wrap {
    .t3mailtoui,
    .footer-link {
      color: $white;
      font-size: 1.25rem;
    }
    .footer-question {
      color: $white;
      display: block;
      margin: .65rem 0;
    }
    .footer-question {
      font-size: 1.125rem;
    }
  }

  .logobar,
  .navigation-wrap {
    max-width: $max-width-content-wide;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    text-align: right;
    @media (max-width: 1279.98px) and (orientation: landscape) {
      max-width: calc(100vw - 60px);
    }
    @media (max-width: 1279.98px) and (orientation: portrait) {
      max-width: calc(100vw - 40px);
    }
  }

  .extended {
    max-width: $extended-width-content-wide;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    @media (max-width: 1519.98px) and (orientation: landscape) {
      max-width: calc(100vw - 60px);
    }
    @media (max-width: 1519.98px) and (orientation: portrait) {
      max-width: calc(100vw - 40px);
    }
  }

  .extended-menu {
    max-width: 1630px;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    @media (max-width: 1689.98px) and (orientation: landscape) {
      max-width: calc(100vw - 60px);
    }
    @media (max-width: 1669.98px) and (orientation: portrait) {
      max-width: calc(100vw - 40px);
    }
  }
  header {
    h1, h2,
    .h1, .h2 {
      margin-bottom: $spacer*2.5;
      word-break: break-word;
    }
    h3, h4, h5, h6,
    .h3, .h4, .h5, .h6 {
      margin-bottom: $spacer*1.5;
    }
  }
  .frame-type-header,
  .frame-type-list {
    header {
        h1, h2,
        .h1, .h2 {
          margin-bottom: 0;
        }
        h3, h4, h5, h6,
        .h3, .h4, .h5, .h6 {
          margin-bottom: 0;
        }
    }
  }
  .breadcrumb-wrap {
    display: block;
  }
  .w-75 {
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
  .lead {
    line-height: 2.5625rem;
  }
  .key-info {
    @include media-breakpoint-down(md) {
      .description-text {
        font-size: 18px;
      }
    }
    .key-items {
      .key-item {
        font-family: $font-family-medium;
        padding-left: 1rem;
        margin-bottom: .25rem;

        &:last-child {
          margin-bottom: 3.75rem;
          @include media-breakpoint-down(md) {
            margin-bottom: 2.5rem;
          }
        }
      }
      .-fix {
        margin-top: -1rem;
        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }
      .svg-icon {
        width: 3.75rem;
        height: 3.75rem;
        color: $gray-900;
        --color1: #434c53;
      }
      /* keeping this from proto v2 - can be removed */
      @include media-breakpoint-down(md) {
        font-size: 18px;
        .btn {
          font-size: 18px;
          &.btn-icon {
            padding-top: .9375rem;
            padding-bottom: .9375rem;
            line-height: 1.55rem;
          }
        }
      }
      /* keeping this from proto v2 - can be removed */
    }
    .btn-items {
      .btn-item {
        @include media-breakpoint-down(md) {
          font-size: 18px;
          .btn {
            font-size: 18px;
            &.btn-icon {
              padding-top: .9375rem;
              padding-bottom: .9375rem;
              line-height: 1.55rem;
            }
          }
        }

        margin-bottom: 2.5rem;
        @include media-breakpoint-down(md) {
          margin-bottom: 1.875rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
  .frame-type-image {
    h2 {
      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
    }
    .ce-gallery {
      margin-bottom: 25px;

      @include media-breakpoint-down(md) {
        margin-bottom: 15px !important;
      }
      .row:last-child {
        /* desktop - cols on last row has NO padding, mobile has */
        .col.frame-space-after-extra-small {
          padding-bottom: 0 !important;
          @include media-breakpoint-down(md) {
            padding-bottom: 30px !important;
          }
        }
        /*mobile - only last col hasNO padding */
        @include media-breakpoint-down(md) {
          .col.frame-space-after-extra-small:last-child {
            padding-bottom: 0 !important;
          }
        }
      }
    }
  }
  .frame-type-text {
    h3 {
      @include media-breakpoint-down(md) {
        margin-bottom: 0.7rem !important;
      }
    }
  }
  .image-flex {
    @include media-breakpoint-down(md) {
      margin-left: 9px;
      margin-right: 9px;
    }
  }
  .btn {
    &.btn-icon{
      padding-top: 0.5rem;
      padding-bottom: .5rem;
      .svg-icon {
        &.download-icon {
          width: 2.19rem;
          height: 2.19rem;
          @include media-breakpoint-down(md) {
            width: 50px;
            height: 35px;
          }
        }
      }
      &.no-text {
        line-height: 0.7;
      }
    }
  }
}
.downloads {
  .image {
    margin-bottom: 30px;
  }
  @include media-breakpoint-down(md) {
    .btn {
      font-size: 18px;
    }
  }
}

// version2 end

//.fade { visibility: hidden; transition: visibility $animation-base, opacity $animation-base; }
//.fade.in { visibility: visible; }
html {
  /*overflow: hidden;
  overflow-y: scroll;*/
  @include media-breakpoint-down(md) {
    font-size: 14.223px;
  }
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}
body {
  overflow-x: hidden;
  max-width: 100vw;
  scroll-behavior: smooth;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.vh-100 {
  height: calc(var(--vh, 1vh) * 100);
}
.vh-75 {
  height: calc(var(--vh, 1vh) * 75);
}
.vh-50 {
  height: calc(var(--vh, 1vh) * 50);
}


.main-footer-wrap {
  .footer-link {
    color: $white;
    font-size: 1.25rem;
  }
  .footer-question {
    color: $white;
    display: block;
    margin: .65rem 0;
  }
  .footer-question {
    font-size: 1.125rem;
  }
}

.breadcrumb-wrap {
  display: none;
}


.frame-space-before-extra-small { padding-top: 40px !important; }
.frame-space-before-small { padding-top: 55px !important; }
.frame-space-before-small46 { padding-top: 46px !important; }
.frame-space-before-medium { padding-top: 70px !important; }
.frame-space-before-large { padding-top: 100px !important; }
.frame-space-before-extra-large { padding-top: 125px !important; }
.frame-space-after-extra-small { padding-bottom: 40px !important; }
.frame-space-after-small { padding-bottom: 55px !important; }
.frame-space-after-small46 { padding-bottom: 46px !important; }
.frame-space-after-medium { padding-bottom: 70px !important; }
.frame-space-after-large { padding-bottom: 100px !important; }
.frame-space-after-extra-large { padding-bottom: 125px !important; }

@include media-breakpoint-down(sm) {
  .frame-space-before-extra-small { padding-top: 30px !important; }
  .frame-space-before-small { padding-top: 35px !important; }
  .frame-space-before-small46 { padding-top: 30px !important; }
  .frame-space-before-medium { padding-top: 40px !important; }
  .frame-space-before-large { padding-top: 70px !important; }
  .frame-space-before-extra-large { padding-top: 90px !important; }
  .frame-space-after-extra-small { padding-bottom: 30px !important; }
  .frame-space-after-small { padding-bottom: 35px !important; }
  .frame-space-after-small46 { padding-bottom: 30px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 70px !important; }
  .frame-space-after-extra-large { padding-bottom: 90px !important; }
}

@include media-breakpoint-down(md) {
  .frame-space-before-extra-small { padding-top: 30px !important; }
  .frame-space-before-small { padding-top: 35px !important; }
  .frame-space-before-small46 { padding-top: 30px !important; }
  .frame-space-before-medium { padding-top: 40px !important; }
  .frame-space-before-large { padding-top: 70px !important; }
  .frame-space-before-extra-large { padding-top: 90px !important; }
  .frame-space-after-extra-small { padding-bottom: 30px !important; }
  .frame-space-after-small { padding-bottom: 35px !important; }
  .frame-space-after-small46 { padding-bottom: 30px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 70px !important; }
  .frame-space-after-extra-large { padding-bottom: 90px !important; }
}

// content wrapper
.content-wrapper { padding: $content-wrapper-padding 0; }
.element-wrapper { margin-bottom: $spacer*2}

.main-container {
  a:not(.btn) {
    color: $cyan;
  }
}
.logobar,
.navigation-wrap {
  max-width: $max-width-content-wide;
  margin: 0 auto;
  width: 100%;
  padding: 0 18px;
}

.extended {
  max-width: $extended-width-content-wide;
  margin: 0 auto;
  width: 100%;
  padding: 0 18px;
}

.container-wrap {
  max-width: 1024px;
  margin: 0 auto;
}

.contact-wrap {
  padding-left: 3rem;
}

h1,.h1 {
  font-size: $h1-font-size;
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2.933;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 2.375;
  }
}
h2,.h2 {
  font-size: $h2-font-size;
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2.222;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 1.875;
  }
}
h3,.h3 {
  font-size: $h3-font-size;
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 1.875;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 1.5;
  }
}
h4,.h4 {
  font-size: $h4-font-size;
  font-family: $font-family-light;
  color: $gray-900;
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 1.4375;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 1.25;
  }
}
h5,.h5 {
  font-size: $h5-font-size;
  font-family: $font-family-medium;
  color: $gray-900;
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 1.4375;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 1.25;
  }
}
h6,.h6 {
  font-size: $h6-font-size;
  font-family: $font-family-bold;
  color: $gray-600;
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 1.4375;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 1.25;
  }
}
.lead {
  font-size: $font-size-base * 1.423;
  margin-right: 10rem;
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 1.423;
    margin-right: 0;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 1.375;
  }
}
.statement {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2.625;
  }
  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 2.125;
  }
}


/*faultmessages*/
$redlight: #F2E5E5;
$bluelight: #ECF2FB;
$messageshadow: 0 2px 4px rgba($black, .1) !default;
$slidershadow: 0px 2px  4px rgba($black,.3) !default;

.message-tittle {
  font-size: $h1-font-size;
}
.message-box {
  padding:$spacer;
  border-left: 10px solid $red;
  background: $white;
  @include box-shadow($messageshadow);
}
.message{
  border: 1px solid $red;
  margin-bottom: $grid-gutter-width;
  .icon {
    width: 80px;
    color: $white;
    text-align: center;
    font-size: 3rem;
    padding-top: 1rem;
  }
  .text {
    padding: .5rem 1rem;
    p {
      font-family: "Open Sans Bold";
    }
  }
  &.primary{
    border-color: $red;
    .icon {
      background: $red;
    }
    .text {
      color: $red;
      background: $redlight;
    }
  }
  &.secondary{
    border-color: $secondary;
    .icon {
      background: $secondary;
    }
    .text {
      color: $secondary;
      background: $bluelight;
    }
  }
}

/* keyvisual */
.box-slide-wrapper {
  margin: 0 auto;
  max-width: 574px;
  padding: 20px 82px;
  transform: translateY(-50%);
  .swiper-controls button {
    transform: translateY(-50%);
    width: 82px;
    height: 82px;
    @include box-shadow($slidershadow);
    text-align: center;
    color: $red;
    background-size: auto;
    background-repeat: no-repeat;
    border: none;
    font-size: 2rem;
    font-weight: bold;
    outline: none;
    margin: 0;
    &.slide-prev {
      left:0;
      @include gradient-x($white, $gray-500);
    }
    &.slide-next {
      right:0;
      @include gradient-x($gray-500, $white);
    }
  }

  .boxes-slider {
    .swiper-wrapper {
      margin-left: -25px;
    }
    .slide-icon-box {
      width: 120px;
      height: 120px;
      background: $red;
      @include gradient-x($red, $reddark);
      @include box-shadow($slidershadow);
      color: $white;
      padding: 1rem 0.5rem 0.5rem;
      margin: 0 auto;
    }
    .swiper-slide-next .slide-icon-box {
      @include gradient-x($reddark,$red);
    }
    .swiper-slide {
      width: 120px;
    }
    .swiper-slide-active {
      width: 174px;
      @include box-shadow($slidershadow);
      .slide-icon-box {
        width: 174px;
        height: 174px;
        background-image: none;
        background: $red;
        padding: 1rem;
        div {
          transform: scale(1.45);
        }
        .fas {
          line-height: inherit;
        }
      }
    }
  }
}

/*iconboxes*/
.icon-box {
  background: $white;
  @include box-shadow($messageshadow);
  margin-bottom: $grid-gutter-width;
  .icon {
    width: 80px;
    color: $white;
    text-align: center;
    font-size: 3rem;
    padding: 1rem 0;
    background: $secondary;
  }
  .text {
    padding: .5rem 1rem;
    &.with-image {
      position: absolute;
      top: 0;
      left:0;
      bottom: 0;
      color: $white;
    }
  }
  .embed-responsive {
    .focus-wrap {
      position: absolute;
      top:  0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:before{
      padding-top: 56%;
    }
  }

  .icobox-link {
    font-family: "Open Sans SemiBoldItalic";
    i {
      margin-left: .5rem;
    }
  }
  .date {
    font-family: "Open Sans Bold";
  }
  .inside-box {
    background: $light;
    border: 5px solid $gray-400;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .box-item {
    border:1px solid $red;
    border-left-width: 10px;
    padding:.5rem;
    margin-bottom: .5rem;
  }
}
#languageMenu {
  > .container-fluid {
    padding: 0 $grid-gutter-width/2;
    margin: 0 auto;
    max-width: $max-width-content-wide;
  }
}

.container-extended-dark,
.container-narrow-dark {
  background: $gray-900;
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $white;
  }
}
.container-extended-light,
.container-narrow-light {
  background: $gray-400;
}

.container-narrow-dark,
.container-narrow-light,
.container-narrow {
  .main-footer-wrap > .content-fluid,
  > .content-fluid {
    padding: 0 $grid-gutter-width/2;
    margin: 0 auto;
    max-width: $max-width-content-wide;
  }
  > header {
    max-width: $max-width-content-wide;
    margin: 0 auto;
    padding: 0 $grid-gutter-width/2;
  }
}

.container-extended-dark,
.container-extended-light,
.container-extended {
  .main-footer-wrap > .content-fluid,
  > .content-fluid {
    padding: 0 $grid-gutter-width/2;
    margin: 0 auto;
    max-width: $extended-width-content-wide;
  }
  > header {
    max-width: $extended-width-content-wide;
    margin: 0 auto;
    padding: 0 $grid-gutter-width/2;
  }
}

/* image/text element */
.image-text-element {
  @include media-breakpoint-up(md) {
    .leftp {
      padding-left: 3.4375rem !important;
    }
    .rightp {
      padding-right: 3.4375rem !important;
    }
    .text {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include media-breakpoint-down(md) {
    overflow: hidden;
    font-size: 18px;
    .btn {
      font-size: 18px;
    }
  }
  .image-flex {
    flex: 0 0 0;
    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100vw;
      overflow: hidden;
    }
    @include media-breakpoint-only(md)  {
      margin-left: 18px;
    }
  }
  .text-wrap-left {
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      display: block;
      background: $gray-500; /* use color constant from */
      right: -50vw;
      left: 0;
      height: 100%;
      top: 0;
      position: absolute;
      z-index: -1;
    }
    p {
      margin: 0;
    }
  }

  .image-wrap-left {
    img {
      float: right;
    }
  }

  a:not(.cta-button-grau):not(.cta-button-weiss):not(.cta-button-blau) {
    color: #00ADD8 !important;
    &:focus,
    &:hover {
      color: $cyan !important;
    }
  }
  .text-white,
  .text-light {
    color: #FFFFFF !important;
    h1, h2, h3, h4, h5, h6,
    .h1 .h2, .h3, .h4, .h5, .h6 {
        color: #ffffff !important;
    }

    a.btn {
      background-color: rgba(255,255,255,0.15);
      border-color: rgba(255,255,255,0);
      color: #fff !important;
      &:focus,
      &:hover {
        background-color: rgba(255,255,255,0);
        border-top-color: #fff;
        border-bottom-color: #fff;
      }
    }
  }
  .text-darker,
  .text-dark {
    color: #434C53 !important;
    h1, h2, h3,
    .h1 .h2, .h3 {
        color: #002D72 !important;
    }
    h4, h5, h6,
    .h4, .h5, .h6 {
      color: $gray-900 !important;
    }

    a.btn {
      background-color: rgba(255,255,255,0.15);
      border-color: rgba(255,255,255,0);
      color: $gray-900 !important;
      &:focus,
      &:hover {
        background-color: rgba(255,255,255,0);
        border-top-color: $gray-900;
        border-bottom-color: $gray-900;
      }
    }
  }

  .text-dark-on-white {
    color: $gray-900 !important;
    h1, h2, h3,
    .h1 .h2, .h3 {
        color: #002D72 !important;
    }
    h4, h5,
    .h4, .h5 {
      color: #434C53 !important;
    }
    h6, .h6 {
      color: #B0B6B8 !important;
    }

    a.btn {
      background-color: rgba(237,241,242,1);
      border-color: rgba(237,241,242,0);
      color: $gray-900 !important;
      &:focus,
      &:hover {
        background-color: rgba(237,241,242,0);
        border-top-color: $gray-900;
        border-bottom-color: $gray-900;
      }
    }
  }
}

.bg-graubraun {
  color: $white;
}

.svg-icon {
  display: inline-block;
  width: 1.234rem;
  height: 1.234rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  &.svg-icon-2x {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

.metanavigation {
  .svg-icon {
    height: 1.334rem;
  }
}

.statement {
  font-family: $font-family-light;
  font-size: 3.125rem;
  line-height: 3.625rem;
  @include media-breakpoint-down(md) {
    font-size: 2.3905rem;
    line-height: 2.949rem;
  }
}

.lead {
  font-family: $font-family-light;
}

.family-light {
  font-family: $font-family-light;
}

.family-medium {
  font-family: $font-family-medium;
}

.family-black {
  font-family: $font-family-black;
}

.border-line {
  border-top: 1px solid #707070 !important;
  height: 1px;
  @include media-breakpoint-down(md) {
    &.w-25 {
      width: 97px !important;
    }
  }
}

.btn:focus {
  outline: none;
  box-shadow: none !important;
}

@media (min-width: 576px) {
  .text-column {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
  }
}

.image-caption {
  font-family: $font-family-medium;
  line-height: 22px;
}



blockquote {
  font-family: $font-family-light;
  font-size: $h3-font-size;
  color: $blue;
  line-height: 2.69rem;
  @include media-breakpoint-down(md) {
    line-height: 2.88rem;
  }
  &:before {
    content: "«";
  }
  &:after {
    content: "»";
  }
}
ul {
  padding-left: 20px;
  line-height: 2.5rem;
  ul {
    list-style-type: disc;
    padding-left: 40px;
  }
}
.main-container {
  ol,
  ul {
    line-height: 24px;
    ol,
    ul {
      li {
        margin-bottom: 5px;
      }
    }
    li {
      margin-bottom: 20px;
    }
  }
}

.font-weight-light {
  font-family: "Aeonik-Light";
}

.pt-30 {
  padding-top: 0 !important;
  @include media-breakpoint-down(md) {
    padding-top: 2.11rem !important;
  }
}

@include media-breakpoint-down(md) {
  left: 18px;
  right: initial;
  max-height: 50px;
}

.sticky-top {
  background: white;
  opacity: .9;
}

.productgroupteaser {
  .btn.square {
    padding: 0.5rem;
    @include media-breakpoint-down(sm) {
      padding: 0.56246915rem !important;
      .svg-icon {
        width: 1.265rem;
        height: 1.265rem;
      }
    }
  }
  .item {
    .image {
      overflow: hidden;
      img {
        transition: transform .6s ease-in-out;
      }
    }
    &:hover {
      .image {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
}

.productgroupteaserdynamic {
  .btn.square {
    padding: 0.5rem;
    @include media-breakpoint-down(sm) {
      padding: 0.56246915rem !important;
      .svg-icon {
        width: 1.265rem;
        height: 1.265rem;
      }
    }
  }
  .item {
    .image {
      overflow: hidden;
      img {
        transition: transform .6s ease-in-out;
      }
      .title {
        bottom: 0;
        padding-left: 2.5rem;
        padding-bottom: 1.5rem;
        z-index: 1;
        @include media-breakpoint-down(sm) {
          padding-left: 0;
          padding-bottom: 25px;
        }
      }
      &:after {
        content: '';
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        height: 60%;
        background: transparent linear-gradient(360deg, #000000c2 0%, #54545400 100%) 0% 0% no-repeat padding-box;
      }
    }
    &:hover {
      .image {
        img {
          transform: scale(1.2);
        }
      }
    }
    &:nth-child(2n) {
      .image {
        margin-top: 5rem;
      }
    }
    &:nth-child(4n) {
      .image {
        margin-top: 4.375rem;
      }
    }
    + .item {
      @include media-breakpoint-down(sm) {
        margin-top: 55px;
        .image {
          margin-top: 0;
        }
      }
    }
  }
}

.templatelocal_teaserslider {
  .swiper-slide {
    picture {
      overflow: hidden;
    }
    img {
      transition: transform .6s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}

.line-height-22 {
  line-height: 1.375rem;
}

.fancybox-bg {
  background: #000 !important;
}

.fancybox-is-open .fancybox-bg {
  opacity: .6 !important;
}

.btn.cart.in-basket:hover {
    .show-on-hover {
      display: block !important;
    }
    .hide-on-hover {
      display: none !important;
    }
}

.infinite-ref {
  .infiniteloader {
    display: none;
    .spinner-border {
      width: 2rem;
      height: 2rem;
      border: .15em solid currentColor;
      border-right-color: transparent;
    }
  }
}

.fullwidth-video {
  video {
    object-fit: cover;
  }
}

.hyphenopoly {
  display: flex;
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}

.object-fit {
  object-fit: cover;
}
.v2 .navigation-wrap {
  margin-top: 1rem;
  transition: margin-top .2s ease;
}

//productgroupteaser delays
.animated.pdelay-1 {
  -webkit-animation-delay: 130ms;
  animation-delay: 130ms;
}
.animated.pdelay-2 {
  -webkit-animation-delay: 260ms;
  animation-delay: 260ms;
}
.animated.pdelay-3 {
  -webkit-animation-delay: 390ms;
  animation-delay: 390ms;
}
.animated.pdelay-4 {
  -webkit-animation-delay: 520ms;
  animation-delay: 520ms;
}
.bg-white-90 {
  background-color: rgba($white, .9) !important;
  &.bg-transparent {
    background-color: transparent !important;
  }
}
.embed-responsive {
  .v-vlite {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
