.picture-gallery-element,
.productsSlider,
.insider,
.accordion.horizontal,
.image-text-element,
.mehrbilder {
  .swiper-slide {
    height: auto;
    .card {
      border: 0;
      .focuspoint {
        height: auto;
      }
    }
  }
  .swiper-button-next:after,.swiper-button-prev:after {
    content: none;
  }
  .swiper-button-next:focus,
  .swiper-button-prev:focus {
    outline: none;
  }
  .swiper-button-prev,
  .swiper-button-next {
    .svg-icon {
      color: $gray-900;
      --color1: #434c53;
      &:hover {
        color: $cyan;
        --color1: #00ADD8;
      }
    }
  }
  .swiper-button-next {
    right: -40px;
  }
  .swiper-button-prev {
    left: -40px;
  }
  .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      padding: 22px 0;
      margin: 0 4px;
      border-radius: 0;
      width: 20px;
      transition: $progress-bar-transition;
      opacity: 1;
      background: transparent !important;
      display: inline-flex;
      align-items: center;
      &:before {
        content: "";
        position: relative;
        display: inline-block;
        height: 3px;
        width: 100%;
        background: $gray-600;
      }
      &:focus {
        outline: none;
      }
      &.hover:not(.swiper-pagination-bullet-active),
      &:hover {
        &:before {
          background: $cyan !important;
        }
        opacity: 1 !important;
      }
    }
    .swiper-pagination-bullet-active {
      &:before {
        height: 4px;
        background: $blue;
        opacity: 1;
      }
    }
  }
}

.text-white,
.bg-gray,
.container-extended-dark,
.container-narrow-dark {
  .swiper-pagination-bullet {
    &:before {
      background: $white !important;
    }
    @include media-breakpoint-down(md) {
      opacity: 1 !important;
    }
  }
  .swiper-pagination-bullet-active {
    &:before {
      background: $blue !important;
    }
    opacity: 1 !important;
    @include media-breakpoint-down(md) {
      &:before {
        background: $cyan !important;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    .svg-icon {
      color: $white;
      --color1: #FFFFFF;
    }
  }
}

.vw-50 {
  width: 50vw;
  @include media-breakpoint-down(md) {
    width: 100vw;
  }
}
.mw-50vw {
  max-width: 50vw;
  @include media-breakpoint-down(md) {
    max-width: 100vw;
  }
}

.productsSlider,
.mehrbilder {
  .swiper-container {
    @include media-breakpoint-down(md) {
      margin-right: -20px;
    }
  }
  .card-text {
    font-family: $font-family-light;
    font-size: 1.5625rem;
    line-height: 2rem;
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }
  .svg-icon {
    width: 1.55rem;
    height: 1.55rem;
  }
  .swiper-pagination {
    .swiper-pagination-bullet-active {
      width: 70px;
      @include media-breakpoint-down(sm) {
        width: 20px !important;
      }
    }
  }
}
.picture-gallery-element,
.accordion.horizontal,
.image-text-element {
  .swiper-button-prev,
  .swiper-button-next {
    margin-top: 0;
    opacity: 0;
    top: 0;
    height: 100%;
    width: 100px;
    &:hover {
      .svg-icon {
          color: $cyan;
          --color1: #00ADD8;
      }
    }
    .svg-icon {
      color: $white;
      --color1: #FFF;
      &:hover {
        color: $cyan;
        --color1: #00ADD8;
      }
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .image-wrap,
  .image-wrap-right,
  .image-wrap-left {
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 1;
      }
    }
    img {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}

.accordion.horizontal,
.picture-gallery-element,
.image-text-element,
.mehrbilder {
  .swiper-button-next,
  .swiper-button-prev {
    &.swiper-button-disabled {
      display: none !important;
    }
  }
}

.ratio-fullwidthteaser {
  @include media-breakpoint-up(lg) {
    position: absolute !important;
  }
  &:after {
    padding-bottom: 100% !important;
    @include media-breakpoint-down(md) {
      padding-bottom: 64% !important;;
    }
  }
}

.fullwidthteaser-minheight {
  @include media-breakpoint-up(lg) {
    min-height: 30vw;
  }
}
