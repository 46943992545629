/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.filter .modal-dialog,
.modal.basket .modal-dialog {
  position: fixed;
  margin: auto;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.filter .modal-dialog {
  width: 550px;
  max-width: 550px;
  @include media-breakpoint-down(md) {
    width: 100%;
    .modal-header {
      padding-bottom: 0 !important;
    }
  }
}
.modal.filter.search {
  .modal-dialog {
    width: 360px;
    max-width: 360px;
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.modal.detail .modal-dialog {
  border: 0;
  width: 660px;
  max-width: 660px;
  padding: 0;
  @include media-breakpoint-down(md) {
    margin: 20px auto !important;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }
}

.modal.basket .modal-dialog {
  width: 420px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.modal.filter .modal-content,
.modal.basket .modal-content {
  height: 100%;
}

.modal.filter .modal-body,
.modal.basket .modal-body {
  padding: 1.875rem 0 0 0;
}

/*Left*/
.modal.filter.fade .modal-dialog {
  left: -550px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.filter.fade.show .modal-dialog {
  left: 0;
}

/*Right*/
.modal.basket.fade .modal-dialog {
  right: -375px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.basket.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */

.modal.alertmsg {
  .modal-content {
    border: none;
    box-shadow: 0px 0px 20px #00000029;
  }
  .modal-body {
    padding: 0 1.875rem;
    @include media-breakpoint-down(md) {
      padding: 0 1.25rem;
    }
  }
  .modal-header {
    padding: 1.25rem 1.875rem 1.25rem 1.875rem;
    @include media-breakpoint-down(md) {
      padding: 1.25rem;
    }
    border: 0;
    .close {
      margin: 0;
      padding: 0;
      .svg-icon {
        width: 3.1rem;
        height: 3.1rem;
      }
    }
  }
  .modal-footer {
    border: 0;
    padding: 1.25rem 1.875rem 1.875rem 1.875rem;
    @include media-breakpoint-down(md) {
      padding: 1.25rem;
    }
  }
}

.modal.detail {
  .modal-body {
    padding: 1.875rem 1.875rem 2rem 1.875rem;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(md) {
      padding: 1.25rem;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 1.25rem;
    }
  }
  .modal-header {
    padding: 1.875rem 2.5rem 2.5rem 2.5rem;
    @include media-breakpoint-down(md) {
      padding: 1.875rem 1.25rem 1.25rem 1.25rem;
    }
    border: 0;
    .close {
      margin: 0;
      padding: 0;
      .svg-icon {
        width: 3.1rem;
        height: 3.1rem;
      }
    }
  }
  .modal-footer {
    border: 0;
    padding: 1rem 1.875rem 2.5rem 1.875rem;
    @include media-breakpoint-down(md) {
      padding: 1rem 1.25rem 1.25rem 1.25rem;
    }
  }
}

.modal.search,
.modal.filter,
.modal.basket {
  .modal-content {
    border-radius: 0;
    border: none;
    //padding: 1.875rem 2.5rem 3.75rem;

    .modal-header {
      padding: 1.875rem 2.5rem 2.5rem 2.5rem;
      @include media-breakpoint-down(md) {
        padding: 1.875rem 1.25rem 1.25rem 1.25rem;
      }
      border: 0;
      .close {
        margin: 0;
        padding: 0;
        .svg-icon {
          width: 3.1rem;
          height: 3.1rem;
        }
      }
      .modal-title {
        @include media-breakpoint-down(md) {
          font-size: 2.125rem;
        }
      }
    }
    .modal-body {
      padding: 0 2.5rem;
      height: calc(100vh - 260px);
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        padding: 0 1.25rem;
        height: calc(100% - 170px);
      }
      .custom-checkbox {
        padding-left: 2.5rem;
        cursor: pointer;
        .custom-control-label {
          cursor: pointer;
          &:hover {
            &:enabled {
              color: $cyan;
            }
          }
          &:after,
          &:before {
            width: 1.5rem;
            height: 1.5rem;
            top: 0.2rem;
            left: -2.5rem;
          }
          &:before {
            border: $gray-900 solid 1px;
          }
        }
        .custom-control-input:checked ~ .custom-control-label {
          &:after {
            background: $gray-900;
            transform: scale(0.7);
          }
          &:before {
            border: $gray-900 solid 1px;
            background-color: $white;
          }
        }
        .custom-control-input:disabled ~ .custom-control-label {
          cursor: not-allowed;
        }
      }
      .no-result-text {
        @include media-breakpoint-down(md) {
          font-size: 1.55125rem;
          line-height: 2.125rem;
        }
      }
      hr {
        margin-top: 0;
        margin-bottom: 1.25rem;
        border-color: $modal-border;
      }
      .filters,
      .cart-items-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        .filter-title {
          font-family: $font-family-bold;
        }
        .filter-box {
          margin-bottom: 3.125rem;
        }
      }
      .filter-info{
        margin-bottom: 3.125rem;
        #filter-reset {
          .svg-icon {
            margin-top: -5px;
            margin-right: -5px;
          }
        }
      }
    }
    .modal-footer {
      border: 0;
      padding: 1rem 2.5rem 3.75rem 2.5rem;
      @include media-breakpoint-down(md) {
        padding: 1rem 1.25rem 1.25rem 1.25rem;
      }
    }
  }
}

.btn.filter,
.btn.cart {
  .filter-info,
  .cart-info {
    display: none;
  }
  .svg-icon {
    width: 3rem;
    height: 3rem;
    color: $gray-900;
    --color1: #434c53;
    @include media-breakpoint-down(md) {
      width: 3.34rem;
      height: 3.34rem;
      margin: -1.2rem 0.45rem -1rem 0;
      flex-shrink: 0;
    }
  }
  &.btn-primary {
    .svg-icon {
      color: $white;
      --color1: #FFF;
    }
    .filter-info,
    .cart-info {
      display: inline;
    }
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 0 !important;
  }
  .modal-btn {
    @include media-breakpoint-down(md) {
      width: 100%;
      padding: .935rem 1.6rem !important;
    }
  }
}
.btn.cart {
  @include media-breakpoint-down(md) {
    width: 100%;
    padding: .935rem 1.6rem !important;
  }
}

.border-modal {
  border-color: $modal-border !important;
}

.modal-footer {
  .btn {
    font-size: 1.27125rem;
  }
}
