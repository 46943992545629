.form-control {
  box-shadow: none;
  outline: none;
}
form {
  .form-group {
    margin-bottom: .2rem;
  }
  label {
    &.form-label {
      font-family: $font-family-bold;
      color: $gray-600;
      font-size: 0.75rem;
      margin: 0;
    }
    &.custom-control-label {
      color: $gray-800;
      line-height: 1.375rem;
      &:before {
        border: 1px solid $gray-900;
      }
      &:after {
        transform: scale(.7);
      }
      &:before,
      &:after {
        width: 1.625rem;
        height: 1.625rem;
        top: .1rem;
        left: -2.5rem;
      }
      &:hover {
        color: $cyan;
      }
    }
    &.labelfocus {
      color: $cyan;
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &:after {
      background: $gray-900;
      transform: scale(0.7);
      background-image: none !important;
    }
    &:before {
      border: $gray-900 solid 1px;
      background-color: $white;
    }
  }
  textarea,
  input {
    &.form-control {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      padding: 0 0 0.47rem 0;

      &:focus {
        box-shadow: none;
        border-color: initial;
        + .form-label {
          color: $cyan;
        }
      }
    }
  }
  textarea.form-control {
    height: 30px;
  }
  .custom-radio {
    display: inline-flex;
    padding-left: 2.5rem;
    .custom-control-label {
      &:after {
        border-radius: 50%;
      }
    }
  }
  .custom-checkbox {
    padding-left: 2.5rem;
  }
  fieldset {
    margin-bottom: 1.875rem !important;
    legend {
      font-size: 1.125rem;
      font-family: $font-family-medium;
      margin-bottom: 1.25rem;
    }
    .fieldset-frame {
      border: 1px solid $gray-600;
      padding: 0 1.125rem;
      .blank {
        width: 100%;
        height: 3px;
        top: -2px;
        position: relative;
        background: $white;
        &.bottom {
          top: auto;
          bottom: -2px;
        }
      }
    }
    [class*="col-"] {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  .custom-file {
    height: 3.125rem;
    .custom-file-label {
      height: 3.125rem;
      border-right: 0 !important;
      border-left: 0 !important;
      margin-left: 12.125rem;
      &:after {
        height: 3.125rem;
        line-height: 3.125rem;
        text-align: center;
        font-family: $font-family-medium;
        right: auto;
        left: -12.125rem;
        border: 0;
        width: 11.125rem;
        background-color: $gray-400;
        color: $gray-900;
        padding: 0 !important;
      }
    }
  }
}

.form-border-bottom {
  border-bottom: $input-border-width solid $input-border-color;
}
.has-error {
  .form-label {
      color: $red;
  }
  .form-border-bottom,
  .hf-invalid,
  .error {
    border-color: $red;
  }
  .hf-warning,
  .invalid-feedback {
    display: block;
    color: $red;
    font-family: $font-family-medium;
    font-size: .875rem;
  }
}

.microsite {
  form {
    label {
      &.form-label {
        font-family: Aeonik-Regular;
        font-size: 1.375rem;
        line-height: 1.625rem;
      }
      &.custom-control-label {
      }
    }
    .floatinglabel {
      label {
        position: absolute;
        bottom: 4px;
        transition: all .1s ease-in-out;
      }
      .form-control {
        padding-top: 1.8rem;
      }
      &>.form-control::placeholder {
        color: transparent;
      }
      &>.form-control:focus~label,
      &>.form-control:not(:placeholder-shown)~label {
        bottom: auto;
        top: 0;
      }
    }
  }
}
.bg-blue {
  form {
    h1,h2,h3,h4,h5,h6,span,p {
      color: $white;
    }
    label {
      color: $white;
      &.form-label {
        color: $white;
      }
      &.custom-control-label {
        color: $white;
      }
    }
    textarea,
    input {
      &.form-control {
        background-color: $blue;
        color: $white;
        &:focus {
          color: $white;
          border-color: $white;
        }
      }
    }
  }
}
