.flyout-menu {
  width: 100%;
  border: 0;
  margin: 0;
}
#mainnavigation {
  .flyout-menu {
    overflow-y: scroll !important;
    max-height: calc(100vh - 132px);
    max-height: calc((var(--vh, 1vh) * 100) - 132px);
  }
  #searchMenu,
  #languageMenu,
  #contactMenu {
    &.flyout-menu {
      overflow: hidden !important;
    }
  }
}
