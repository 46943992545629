.sticky_sentinel {
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
  z-index: -1;
}
.sticky_sentinel--top {
  height: 25%;
  top: -20px;
}
.sticky_sentinel--bottom {
  height: 25%;
  bottom: -20px;
}

.v2 {
  .freeze {
    min-height: 100vh;
    @media (max-width: 1279.98px) and (orientation: landscape) {
      .content-fluid {
        max-width: calc(100vw - 60px);
      }
    }
    @media (max-width: 1279.98px) and (orientation: portrait) {
      .content-fluid {
        max-width: calc(100vw - 40px);
      }
    }
    @media (max-width: 767.98px) and (orientation: landscape) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (max-width: 767.98px) and (orientation: portrait) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .scrollernav {
      min-height: 7.5rem;
      @include media-breakpoint-down(md) {
        right: 0;
      }
    }
  }
}

.insider {
  .swiper-button-next {
    right: -32px;
  }
  .swiper-button-prev {
    left: -32px;
  }
}

.freeze-swipper {
  padding-left: 3rem;
  padding-right: 4.25rem;
  max-height: 57vh;
  @include media-breakpoint-down(md) {
    padding: 0;
    max-height: 73vh;
  }
}

.z-index-0 {
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.f-sticky {
  top: -150px !important;
  position: fixed !important;
}

.freeze {
  -webkit-overflow-scrolling: auto;
  position: relative;
  /*min-height: 100vh;*/
  &.active {
    z-index: 10000;
    padding-right: 16px;
/*    @include media-breakpoint-down(md) {
      padding-right: 0;
    }*/
  }

  .swiper-container {
    @include media-breakpoint-down(md) {

    }
  }
  .subheader {
    font-size: 1.25rem;
  }
  transition: background-color,color .25s ease-in-out;
  &.text-white {
    .navbar-toggler,
    h2,h4 {
      color: white;
    }
    .scrollernav {
      .svg-icon {
        color: $white;
        __color1: #ffffff;
        &:hover {
          color: $body-color;
          __color1: #434c53;
        }
      }
    }
    .swiper-pagination-bullet-active,
    .swiper-pagination-bullet:hover,
    .svg-icon:hover {
      color: $blue !important;
      --color1: #002D72;
      @include media-breakpoint-down(md) {
        color: inherit;
      }
    }
    .item-content {
      a {
        color: $cyan;
        &:hover {
          color: $cyan;
          text-decoration: underline;
        }
      }
      h1,h2,h3,h4,h5,h6 {
        color: $white;
      }
    }
    .slidernav-mobile {
      .svg-icon {
        --color1: $white;
        &:hover {
          --color1: $white;
        }
      }
    }
  }
  .mobile-button-next {
    padding: 13px;
    margin-top: 19px;
    background-color: rgba(255,255,255,0);
    .svg-icon {
      width: 0.8rem;
      height: 0.8rem;
    }
    &:focus {
      outline: none !important;
    }
  }
  .slidernav {
    @include media-breakpoint-down(md) {
      position: absolute;
      top: 100%;
      background: rgba(255,255,255,.95);
      width: 100%;
      color: $body-color;
      z-index: 2;
      .freeze-paginator {
        padding: 1rem 1rem 0.5rem 1rem;
      }
     }
  }
  .slidernav-mobile {
    .navbar-toggler {
      font-size: 18px;
      @include media-breakpoint-down(md) {
        &:focus {
          outline: 0;
        }
      }
      &:not(.collapsed) {
        .svg-icon {
          transform: rotate(180deg);
        }
      }
    }
    .slidernav {
      @include media-breakpoint-down(md) {
        bottom: 100%;
        top: auto;
        &:focus {
          outline: 0;
        }
      }
    }
  }
  .scrollernav {
    position: relative;
    z-index: 3;
    @include media-breakpoint-down(md) {
      position: absolute;
      right: 1rem;
      &.bottom {
        bottom:0;
      }
    }
    .svg-icon {
      cursor: pointer;
      width: 2.88rem;
      height: 2.88rem;
      @include media-breakpoint-down(md) {
        width: 2rem;
        height: 2rem;
      }
      color: $body-color;
      __color1: #434c53;
      &:hover {
        color: $white;
        __color1: #ffffff;
      }
    }
  }
  .swiper-pagination-bullet {
    font-size: 1.25rem;
    margin-bottom: .75rem;
    width: auto;
    height: auto;
    opacity: 1;
    background: none;
    border-radius: 0;
    &:hover,
    &.swiper-pagination-bullet-active {
      color: $body-color;
    }
    .svg-icon {
      margin-right: .45rem;
      margin-top: -2px;
    }
    @include media-breakpoint-down(md) {
      color: $body-color !important;
      .svg-icon {
        color: $blue;
      }
    }
  }
  .freeze-scrollbar-wrap {
    position: absolute;
    right: 0;
    top: 0;
    height: 65%;
    max-height: 65%;
    flex-basis: 65%;
    min-width: 28px;
    z-index: 1;
    @include media-breakpoint-down(md) {
      height: 65vh;
      /*height: calc(var(--vh, 1vh) * 65);*/
      max-height: 65vh;
      flex-basis: 65vh;
    }
  }
  .freeze-text {
    transition: all 1s ease-in-out;
    transform: translateY(150%);
    opacity: 0;
  }
  .swiper-slide {
    transition: opacity 0.5s ease-in-out;
    opacity: 0 !important;
  }

  .swiper-slide-active.show,
  .swiper-slide-active:not(.fade) {
    opacity: 1 !important;
/*    scrollbar-width: none;
    .freeze-text.show {
      opacity: 1;
      transform: translateY(0);
    }*/
  }
  .freeze-scrollbar {
    overflow: hidden;
    position: relative;
    width: 1px;
    height: 100%;
    background: $white;
    .swiper-scrollbar-drag {
      border-radius: 0;
      background: $blue;
      transition-duration: 0.25s;
      transition-timing-function: ease;
      transition-property: transform,-webkit-transform;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        top: -1000%;
        height: 1000%;
        transform: translate3d(0,0,0);
        transition-duration: 0.25s;
        transition-timing-function: ease;
        transition-property: transform,-webkit-transform;
        background: $blue;
      }
    }
  }
}
.h-85 {
  height: 85%;
  @include media-breakpoint-down(md) {
    height: 65%;
  }
}
.mh-70 {
  max-height: 70%;
}
.mh-75 {
  max-height: 75%;
  height: 75vh;
  /*height: calc(var(--vh, 1vh) * 75);*/
  @include media-breakpoint-down(md) {
    max-height: 100%;
    height: 100vh;
    /*height: calc(var(--vh, 1vh) * 100);*/
  }
}
.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: $gray-600 #B0B6B84D;
  &::-webkit-scrollbar {
    width: 10px;
    @include media-breakpoint-down(md) {
      width: 8px;
    }
  }
  &::-webkit-scrollbar-track {
    background: #B0B6B84D;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-600 ;
    border-radius: 6px;
    border: 3px solid #B0B6B84D;
  }

}

.text-white.overflow-y-auto {
  scrollbar-color: $white #FFFFFF4D;
  &::-webkit-scrollbar-track {
    background: #FFFFFF4D;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $white ;
    border: 3px solid #FFFFFF4D;
  }

}

/*
.freeze-overflow-hidden {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  -webkit-overflow-scrolling: auto;
}
*/
.text-base-white {
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet:hover,
  .svg-icon:hover {
    color: $blue !important;
    --color1: #002D72;
    @include media-breakpoint-down(md) {
      color: inherit;
    }
  }
  .freeze-scrollbar {
    background: $scrollbar;
  }
  .item-content {
    a {
      color: $cyan;

      &:hover {
        color: $cyan;
        text-decoration: underline;
      }
    }
    h1,h2,h3 {
      color: $blue;
    }
    h4,h5 {
      color: $gray-900;
    }
    h6 {
      color: $gray-600;
    }
  }
}

.text-base {
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet:hover,
  .svg-icon:hover {
    color: $blue !important;
    --color1: #002D72;
    @include media-breakpoint-down(md) {
      color: inherit;
    }
  }
  .freeze-scrollbar {
    background: $scrollbar;
  }
  .item-content {
    a {
      color: $cyan;
      &:hover {
        color: $cyan;
        text-decoration: underline;
      }
    }
    h1,h2,h3 {
      color: $blue;
    }
    h4,h5,h6 {
      color: $gray-900;
    }
  }
  @include media-breakpoint-down(md) {
    .swiper-pagination-bullet-active,
    .swiper-pagination-bullet:hover {
      color: $body-color !important;
    }
  }
}
