@font-face {
  font-family: 'Aeonik-ThinItalic';
  src: url('#{$font-path}Aeonik-ThinItalic.eot');
  src: url('#{$font-path}Aeonik-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-ThinItalic.svg#Aeonik-ThinItalic') format('svg'),
  url('#{$font-path}Aeonik-ThinItalic.ttf') format('truetype'),
  url('#{$font-path}Aeonik-ThinItalic.woff') format('woff'),
  url('#{$font-path}Aeonik-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-Thin';
  src: url('#{$font-path}Aeonik-Thin.eot');
  src: url('#{$font-path}Aeonik-Thin.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-Thin.svg#Aeonik-Thin') format('svg'),
  url('#{$font-path}Aeonik-Thin.ttf') format('truetype'),
  url('#{$font-path}Aeonik-Thin.woff') format('woff'),
  url('#{$font-path}Aeonik-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-RegularItalic';
  src: url('#{$font-path}Aeonik-RegularItalic.eot');
  src: url('#{$font-path}Aeonik-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-RegularItalic.svg#Aeonik-RegularItalic') format('svg'),
  url('#{$font-path}Aeonik-RegularItalic.ttf') format('truetype'),
  url('#{$font-path}Aeonik-RegularItalic.woff') format('woff'),
  url('#{$font-path}Aeonik-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-Regular';
  src: url('#{$font-path}Aeonik-Regular.eot');
  src: url('#{$font-path}Aeonik-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-Regular.svg#Aeonik-Regular') format('svg'),
  url('#{$font-path}Aeonik-Regular.ttf') format('truetype'),
  url('#{$font-path}Aeonik-Regular.woff') format('woff'),
  url('#{$font-path}Aeonik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-MediumItalic';
  src: url('#{$font-path}Aeonik-MediumItalic.eot');
  src: url('#{$font-path}Aeonik-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-MediumItalic.svg#Aeonik-MediumItalic') format('svg'),
  url('#{$font-path}Aeonik-MediumItalic.ttf') format('truetype'),
  url('#{$font-path}Aeonik-MediumItalic.woff') format('woff'),
  url('#{$font-path}Aeonik-MediumItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-Medium';
  src: url('#{$font-path}Aeonik-Medium.eot');
  src: url('#{$font-path}Aeonik-Medium.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-Medium.svg#Aeonik-Medium') format('svg'),
  url('#{$font-path}Aeonik-Medium.ttf') format('truetype'),
  url('#{$font-path}Aeonik-Medium.woff') format('woff'),
  url('#{$font-path}Aeonik-Medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-LightItalic';
  src: url('#{$font-path}Aeonik-LightItalic.eot');
  src: url('#{$font-path}Aeonik-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-LightItalic.svg#Aeonik-LightItalic') format('svg'),
  url('#{$font-path}Aeonik-LightItalic.ttf') format('truetype'),
  url('#{$font-path}Aeonik-LightItalic.woff') format('woff'),
  url('#{$font-path}Aeonik-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-Light';
  src: url('#{$font-path}Aeonik-Light.eot');
  src: url('#{$font-path}Aeonik-Light.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-Light.svg#Aeonik-Light') format('svg'),
  url('#{$font-path}Aeonik-Light.ttf') format('truetype'),
  url('#{$font-path}Aeonik-Light.woff') format('woff'),
  url('#{$font-path}Aeonik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-BoldItalic';
  src: url('#{$font-path}Aeonik-BoldItalic.eot');
  src: url('#{$font-path}Aeonik-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-BoldItalic.svg#Aeonik-BoldItalic') format('svg'),
  url('#{$font-path}Aeonik-BoldItalic.ttf') format('truetype'),
  url('#{$font-path}Aeonik-BoldItalic.woff') format('woff'),
  url('#{$font-path}Aeonik-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-Bold';
  src: url('#{$font-path}Aeonik-Bold.eot');
  src: url('#{$font-path}Aeonik-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-Bold.svg#Aeonik-Bold') format('svg'),
  url('#{$font-path}Aeonik-Bold.ttf') format('truetype'),
  url('#{$font-path}Aeonik-Bold.woff') format('woff'),
  url('#{$font-path}Aeonik-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-BlackItalic';
  src: url('#{$font-path}Aeonik-BlackItalic.eot');
  src: url('#{$font-path}Aeonik-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-BlackItalic.svg#Aeonik-BlackItalic') format('svg'),
  url('#{$font-path}Aeonik-BlackItalic.ttf') format('truetype'),
  url('#{$font-path}Aeonik-BlackItalic.woff') format('woff'),
  url('#{$font-path}Aeonik-BlackItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-Black';
  src: url('#{$font-path}Aeonik-Black.eot');
  src: url('#{$font-path}Aeonik-Black.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-Black.svg#Aeonik-Black') format('svg'),
  url('#{$font-path}Aeonik-Black.ttf') format('truetype'),
  url('#{$font-path}Aeonik-Black.woff') format('woff'),
  url('#{$font-path}Aeonik-Black.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-AirItalic';
  src: url('#{$font-path}Aeonik-AirItalic.eot');
  src: url('#{$font-path}Aeonik-AirItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-AirItalic.svg#Aeonik-AirItalic') format('svg'),
  url('#{$font-path}Aeonik-AirItalic.ttf') format('truetype'),
  url('#{$font-path}Aeonik-AirItalic.woff') format('woff'),
  url('#{$font-path}Aeonik-AirItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: $fa-font-display;
}
@font-face {
  font-family: 'Aeonik-Air';
  src: url('#{$font-path}Aeonik-Air.eot');
  src: url('#{$font-path}Aeonik-Air.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}Aeonik-Air.svg#Aeonik-Air') format('svg'),
  url('#{$font-path}Aeonik-Air.ttf') format('truetype'),
  url('#{$font-path}Aeonik-Air.woff') format('woff'),
  url('#{$font-path}Aeonik-Air.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: $fa-font-display;
}
