.productgroupteaser .item:hover .btn-secondary,
.mehrbilder .card:hover .btn-secondary,
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active {
  &:not(:disabled){
    background-color: $white;
    border-top: $input-btn-border-width solid $gray-900;
    border-bottom: $input-btn-border-width solid $gray-900;
    border-right: $input-btn-border-width solid $white;
    border-left: $input-btn-border-width solid $white;
    &.square {
      border-right: $input-btn-border-width solid $gray-900;
      border-left: $input-btn-border-width solid $gray-900;
    }
  }
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active,
.btn-gray-400:hover, .btn-gray-400:focus, .btn-gray-400:active, .btn-gray-400.active {
  background-color: transparent;
  border-top: $input-btn-border-width solid $gray-900;
  border-bottom: $input-btn-border-width solid $gray-900;
  border-right: $input-btn-border-width solid transparent;
  border-left: $input-btn-border-width solid transparent;
  &.square {
    border-right: $input-btn-border-width solid $gray-900;
    border-left: $input-btn-border-width solid $gray-900;
  }
}

a.btn-white,
a.btn-gray-400 {
  &:hover,
  &:focus,
  &.active {
    border-top-color: $link-border;
    border-bottom-color: $link-border;
  }
}


.btn {
  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
  &.btn-icon {
    padding-top: .34375rem;
    padding-bottom: .34375rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      line-height: 1.55rem;
      font-size: 1.2656rem;
    }
  }
}
.btn-light {
  color: $white;
  background-color: rgba(255,255,255,0.15);
  border-right: 0;
  border-left: 0;
  border-top: $input-btn-border-width solid rgba(255,255,255,0);
  border-bottom: $input-btn-border-width solid rgba(255,255,255,0);
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active {
  background-color: rgba(255,255,255,0) !important;
  color: $white !important;
  border-top: $input-btn-border-width solid $white;
  border-bottom: $input-btn-border-width solid $white;
  border-right: 0;
  border-left: 0;
  &.square {
    border-right: $input-btn-border-width solid $white;
    border-left: $input-btn-border-width solid $white;
  }
}

.btn-bluelight {
  color: $white !important;
}

.btn-outline-pill {
  border-radius: 50rem !important;
  color: $white;
  border: 3px solid $white;
  font-size: 30px;
  padding-top: 26px;
  padding-bottom: 26px;
  @include media-breakpoint-down(lg) {
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 16px;
  }
  &:hover,
  &.active {
    background-color: $white;
  }
}
