.decisionsupport {
  z-index: 1;
  position: relative;

  .title {
    padding-left: 3.75rem;
    padding-bottom: 3rem;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      padding: 0;
      padding-bottom: 6.25rem;
    }
  }

  .item-title {
    @include media-breakpoint-down(sm) {
      margin-bottom: .75rem;
    }
  }

  .item {
    padding: 3.75rem;
    padding-bottom: 5rem;
    @include media-breakpoint-down(sm) {
      padding: 0;
      padding-bottom: 6rem;
    }
    .text {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .bg-circle {
    display: inline-block;
    position: relative;
    margin-right: 1.1875rem;
    .svg-icon {
      width: 3rem;
      height: 3rem;

      @include media-breakpoint-down(sm) {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }

  .bg-circle::before {
    content: '';
    width: 8.625rem;
    height: 8.625rem;
    border-radius: 50%;
    box-shadow: 0 0 0 0.875rem rgba(255, 255, 255, 0.6);
    background: #fff;
    display: inline-block;
    text-align: center;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media-breakpoint-down(sm) {
      width: 5.6rem;
      height: 5.6rem;
      box-shadow: 0 0 0 0.518125rem rgba(255, 255, 255, 0.6);
    }
  }

}
