.sticky {
  .main-header {
    padding-bottom: 60px;
    @include media-breakpoint-down(md) {
      padding-bottom: 35px;
    }
    .nav-container {
      position: fixed;
      background: rgba(255,255,255,.9);
      min-height: auto;
      .navigation-wrap {
        margin-top: 0 !important;
        .hamburger {
          margin: initial !important;
        }
      }
    }
  }
  &.inmode {
    .fini {
      .nav-container {
        background: none;
        min-height: 86px;
      }
    }
    .topbar {
      transform: translateY(-90px);
    }
  }
}



.main-header {
  z-index: 2;
  &.bg-white {
    background-color: rgba($white,0.90) !important;
  }
  @include media-breakpoint-down(md) {
    position: initial !important;
  }
  .nav-relative {
    @include media-breakpoint-down(md) {
      position: relative;
    }
  }
  .nav-container {
    left: 0;
    width: 100%;
    z-index: 2;
    background: rgba($white,1);
    /*transition: all 0.2s ease-in-out;*/
    min-height: var(--logo);
    position: relative;
    &.nav-redesign {
      background: none;
    }
    &.bg-white.nav-redesign {
      background-color: rgba($white,0.90) !important;
    }
    @include media-breakpoint-down(md) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
/*    @include media-breakpoint-up(lg) {
      .navigation-wrap {
        margin-top: 2rem !important;
      }
    }*/
    > .close {
      position: absolute;
      top: 1rem;
      right: 2.5rem;
      font-size: initial;
      text-shadow: none;
      opacity: 1;
    }
    .hamburger {
      outline: 0;
      z-index: 10000;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      &.is-active {
        top: 0;
        right: 10px;
      }
    }
  }

  .topbar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(0);
    transition: transform .2s ease;
    transition-delay: .1s !important;
    z-index: 10;
  }
}
.logobar {
  .logo {
    max-width: 154px;
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 10;
    @include media-breakpoint-down(md) {
      left: 18px;
      top: 0;
      right: initial;
      max-height: 50px;
    }
  }
}

nav.navbar {
  position: initial;
}

#mobileMainnavigation,
#mainnavigation {
  font-family: $font-family-light;
  font-size: 1.25rem;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
  line-height: 1;

  .flyout-menu {
    width: 100%;
    border: 0;
    position: absolute;
    left: 0;
    top: 100%;
    background: $white;
    height: auto;
    overflow: hidden;
    .extended-menu,
    .extended {
      opacity: 1;
      transform: translateY(0);
      transition: transform .25s ease-in-out, opacity .35s ease-in-out;
    }
    &.fade {
      transition: opacity .4s ease !important;
      height: auto;
      display: block;
      .extended-menu,
      .extended {
        transform: translateY(0);
        transition: none;
        opacity: 1;
      }
      &.delay {
        transition-delay: .2s;
      }
      &.show {
        transition-delay: 0s;
        z-index: 1;
      }
    }
    &.collapsing {
      transition: height .3s cubic-bezier(.2, 0, .15, 1);
      height: 0;
      .extended-menu,
      .extended {
        transform: translateY(-20px);
        transition: transform .4s cubic-bezier(.2, 0, .15, 1) .1s, opacity .4s ease .1s;
        opacity: 0;
      }
      &.fade {
        transition: opacity .4s ease !important;
        height: auto;
        display: block;
        &.delay {
          transition-delay: .2s !important;
        }
        .extended-menu,
        .extended {
          transform: translateY(0);
          transition: none;
          opacity: 1;
        }
      }
    }
    &.show {
      transition: height .4s cubic-bezier(.2, 0, .15, 1), opacity .4s ease;
    }
    .extended-menu {
      .row {
        margin-right: -30px;
        margin-left: -30px;
        .menu-col {
          flex: 0 0 28%;
          max-width: 28%;
          padding-right: 30px;
          padding-left: 30px;
          .list-name {
            padding-bottom: 0;
            .nav-link {
              line-height: 1;
              padding-bottom: 1.125rem;
            }
          }
        }
        .navProds {
          padding-top: 2.25rem;
          flex: 0 0 44%;
          max-width: 44%;
          padding-right: 30px;
          padding-left: 60px;
          &.min-height {
            min-height: 650px;
          }
        }
      }
    }
  }


  a {
    color: $gray-900;
    .fa-chevron-right {
      padding: .125rem 0;
    }
  }
  .close {
    position: absolute;
    right: 2.5rem;
    font-size: initial;
    text-shadow: none;
    opacity: 1;
    .svg-icon {
      width: 1.7rem;
      height: 1.7rem;
      color: $gray-900;
      --color1: #434c53;
    }
  }
  .nav-item {
    &.active,
    &:hover {
      > a {
        font-family: $font-family-light;
        color: $cyan;
      }
    }
    &.active > a {
      color: $blue;
    }
  }

  .level-1 {
    font-size: 2.125rem;
    .borders {
      margin-top: -1px;
    }
  }
  .nav-link {
    &.collapsed {
      .fal {
        transform: rotate(180deg);
      }
    }
  }

  .list-name {
    font-family: $font-family-base;
    color: $blue;
    font-size: $font-size-base;
    border-bottom: 1px solid $gray-900;
    padding-bottom: .72rem;
  }

  .borders {
    border-bottom: 1px solid $gray-900;
    position: relative;
    &.active,
    &:hover {
      &:before {
        content:'';
        position: absolute;
        background-color: $cyan;
        width: 100%;
        height: 2px;
        top: -1px;
        left:0;
        z-index: 10;
      }
      &:after {
        content:'';
        position: absolute;
        background-color: $cyan;
        width: 100%;
        height: 2px;
        bottom: -1px;
        left:0;
        z-index: 10;
      }
    }
    &.active {
      &:after,
      &:before {
        background-color: $blue;
      }
    }
    .nav-link {
      padding: .625rem 0;
    }
  }
  #langcol,
  .navSub {
    .borders {
      .nav-link {
        padding: .125rem 0;
      }
    }
  }

  .navbar-nav {
    > .nav-item {
      &:first-child {
        > .nav-link {
          padding-left: 0;
        }
      }
      .nav-link {
        padding-right: 1.125rem;
        padding-left: 1.125rem;
      }
      > .nav-link {
        //&:before {
        //  display: block;
        //  content: attr(title);
        //  height: 0;
        //  overflow: hidden;
        //  visibility: hidden;
        //  font-family: $font-family-base;
        //  padding-right: 1.67rem;
        //  @include media-breakpoint-down(md) {
        //    display: none;
        //  }
        //}
      }

      .level-1 {
        .nav-item {
          .nav-link {
            padding: .625rem 0;
            justify-content: space-between;
            display: flex;
            /*word-break: break-all;*/
            .submenutrigger {
              .fal {
                font-size: 1.5rem;
                vertical-align: middle;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .navSub,
  .navProds {
    overflow: hidden;
  }
  .product-nav {
    /*transform: translateX(0);*/
    transition: transform .35s ease-in-out, opacity .25s ease-in-out;
    opacity: 1;
    &.collapsing {
      /*transform: translateX(-120%);*/
      opacity: 0;
      transition: transform .35s ease-in-out, opacity .25s ease-in-out;
    }
    .nav-item {
      /*font-size: 1.25rem;*/
      &.active,
      &:hover {
        &:before {
          background-color: $cyan;
          height: 1px;
        }
        &:after {
          background-color: $cyan;
          height: 1px;
        }
      }
    }
    .nav-item {
      &.active,
      &:hover {
        a {
          color: $cyan;
        }
      }
    }
  }
  .product-box {
    font-family: $font-family-base;
    transition: transform .35s ease-in-out, opacity .25s ease-in-out;
    opacity: 1;
    &.collapsing {
      opacity: 0;
      transition: transform .35s ease-in-out, opacity .25s ease-in-out;
    }
    h3 {
      font-family: $font-family-base;
      font-size: 1.75rem;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.3125rem;
    }
  }
}

#mainnavigation {
  .collapsing {
    transition: none !important;
  }
  .navbar-nav {
    .level-1 {
      .nav-item:not(.list-name){
        .nav-link {
          font-size: 1.5rem;
          line-height: 1.75rem !important;
          padding: .3rem 0 !important;
        }
      }
    }

    .level2 {
      .nav-item:not(.list-name) {
        .nav-link {
          font-size: 1.1rem;
          line-height: 1.5rem !important;
          padding: .125rem 0 !important;
        }
      }
    }

    .product-box {
      h3 {
        font-size: 1.5rem;
      }

      p {
        line-height: 1.2 !important;
      }
    }
  }
}

.nav-redesign {
  #mainnavigation {
    .navbar-nav {
      .nav-item {
        .nav-link {
          &.level-top {
            position: relative;
            line-height: 1;
            &:after {
              position: absolute;
              content: '';
              border-bottom: 1px solid $cyan;
              width: 0;
              bottom: 5px;
              left: 1rem;
              transition: $transition-base;
            }
            &.active,
            &:hover {
              &:after {
                width: calc(100% - 2rem);
              }
            }
            &:not(.collapsed).trigger-0{
              &:after {
                border-color: $blue !important;
                width: calc(100% - 2rem);
              }
            }
          }
        }
        &:active {
          .level-top {
            &:after {
              width: calc(100% - 2rem);
              border-color: $blue !important;
            }
          }
        }
        &:first-child {
          .level-top {
            &:after {
              left: 0 !important;
            }
            &:not(.collapsed),
            &:hover {
              &:after {
                width: calc(100% - 1rem) !important;
              }
            }
          }
        }
      }
    }
    .flyout-menu {
      background: rgba(255,255,255,0.90);
    }
  }
  #mobileMainnavigation {
    background-color: transparent;
    .mp-level {
      background: rgba($white,0.90);
      .mp-level {
        background: $white;
      }
    }
  }
}

.metanavigation {
  /*margin-right: 11.11rem;*/
  position: absolute;
  z-index: 3;
  right: 0;
  top: 18px;
  .nav-item {
    .nav-link {
      padding: 2px .375rem;
      color: $gray-900;
      margin-top: -5px;
      &:not(.collapsed),
      &:hover {
        color: $blue;
      }
    }
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
}

.text-cyan {
  color: $cyan;
}

#mobileMainnavigation {
  font-size: 2.11rem;
  .search-icon,
  .mp-back {
    font-family: $font-family-medium;
    padding: 3px 0 88px 0;
    display: block;
    font-size: 1.125rem !important;
    .svg-icon {
      width: 2rem;
      height: 2rem;
    }
  }
  .mp-back {
    padding: 32px 0 18px 0 !important;
  }
  .navbar-nav {
    .nav-item {
      border-bottom: 1px solid $gray-900;
      .nav-link {
        padding: .625rem 0 !important;
        justify-content: space-between;
        display: flex;
        .arrow {
          width: 30px;
          text-align: right;
        }
      }
      &.list-name {
        font-size: 1.125rem;
        padding-bottom: .72rem;
        font-family: Aeonik-Medium;
      }
      &.active,
      &:hover {
        > a {
          font-family: $font-family-base;
          color: $blue;
        }
      }
    }
  }

  &.flyout-menu {
    left: 0;
    top: 0;
    position: fixed;
  }
  &.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
  &.show {
    height: 100%;
    width: 100%;
    transition: height .4s cubic-bezier(.2, 0, .15, 1);
  }

  /* cover */
  .mp-level.mp-level-open {
    transform: translate3d(0, 0, 0);
    &.active {
      > .mp-back,
      > .nav {
        visibility: visible;
      }
    }
  }

  .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
    transform: translate3d(100%, 0, 0);
  }

  .mp-level {
    position: absolute;
    padding: .8rem 1.25rem 1.25rem 1.25rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translate3d(100%, 0, 0);
    transition: all 0.5s;
    &:before {
      content: '';
      width: 100%;
      height: 25px;
      /*background-color: $gray-400;*/
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    > .mp-back,
    > .nav  {
      visibility: hidden;
    }
  }
  .level-0 {
    font-size: 1.875rem;
  }
  .level-1 {
    font-size: 1.6875rem;
  }
  .level-2 {
    font-size: 1.4068rem;
  }
  .metanav {
    .nav-link {
      line-height: 1.5;
      font-size: 1.125rem;
      .svg-icon {
        margin-top: -5px;
        margin-right: 10px;
      }
    }
  }
  .contactMenu {
    h3 {
      font-size: 1.875rem;
      .svg-icon {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.contactMenu,
#contactMenu {
  font-family: $font-family-base;
  font-size: 1.25rem;
  line-height: 1.6;
  a {
    color: $cyan !important;
  }
  .row {
    .svg-icon {
      width: 2.375rem;
      height: 2.375rem;
      margin-left: -3rem;
      --color1: #002d72;
      position: absolute;
    }
    .contact-wrap {
      @include media-breakpoint-down(md) {
        margin-bottom: 50px;
      }
      h3 {
        margin-bottom: 0.3rem;
        font-size: 1.95rem;
        @include media-breakpoint-down(md) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0,11px,0) rotate(45deg);
}

.breadcrumb-wrap {
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    &:before {
      display: block;
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      width: 30px;
      background: transparent linear-gradient(90deg, #FFFFFF 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 2;
      width: 30px;
      background: transparent linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    }
    &.start:before {
      left: -30px;
    }
    &.end:after {
      right: -30px;
    }
  }
  .breadcrumb {
    flex-wrap: nowrap;
    white-space: nowrap;
    height: auto;
    margin-bottom: 0;
  }
}

.breadcrumb-item:before {
  width: 1rem;
}
.breadcrumb-item + .breadcrumb-item:before {
  display: none;
}
.breadcrumb-item {
  width: auto;
  white-space: nowrap;
  &:before {
    display: none;
  }
  a {
    color: $blue;
    @include media-breakpoint-down(md) {
      font-size: .92rem;
    }
  }
  a:after {
    display: inline-block;
    content: "";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 .25rem;
    font-size: .7rem;
  }
  a:hover {
    color: #00ADD8;
    text-decoration: none;

  }
}
/* #93191 removed position:fixed because jumps to top when opened in minimized nav */
.fixed-position {
  width: 100%;
  overflow: hidden;
  height:100%;
}


:root {
  --animate-duration: 2s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate {
  visibility: hidden;
  &.fade {
    visibility: visible;
  }
  &.animated {
    visibility: visible;
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
