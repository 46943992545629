.productpattern {
  .item-wrapper {
    display: block;
    img {
      transition: transform .3s ease;
    }
    &:hover {
      color: $body-color;
      text-decoration: none;
      .btn-secondary {
        background-color: $white;
        border-top: $input-btn-border-width solid $gray-900;
        border-bottom: $input-btn-border-width solid $gray-900;
        border-right: $input-btn-border-width solid $white;
        border-left: $input-btn-border-width solid $white;
        &.square {
          border-right: $input-btn-border-width solid $gray-900;
          border-left: $input-btn-border-width solid $gray-900;
        }
      }
      img {
        transform: scale(1.2);
      }
      header:after {
        width: 100px;
      }
    }
    .item-text {
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
  }
  .teaser-item {
    grid-row-start: span 1;
    grid-row-end: span 1;
    grid-column-start: span 1;
    grid-column-end: span 1;
    top: 0;
    margin-bottom: 10rem;

    .svg-icon {
      width: 1.55rem;
      height: 1.55rem;
      @include media-breakpoint-down(md) {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
  .row {
    &.d-md-grid {
      @include media-breakpoint-up(lg) {
        display: grid;
        grid-auto-columns: 1fr;
        grid-column-gap: 36px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto auto;
        margin: 0;
        .col-auto {
          padding: 0;
        }
      }
    }
    @include media-breakpoint-down(md) {
      .col-auto {
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 53px !important;
        top: 0 !important;
        margin-top: 0 !important;
      }
    }
  }
  h2 {
    font-family: 'Aeonik-Light';
    color: $headings-color;
    margin-bottom: 1.25rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 22px;
    }
  }
  h3 {
    font-family: 'Aeonik-Light';
    color: $headings-color;
    margin-bottom: .75rem !important;
    margin-top: 1.875rem;
    @include media-breakpoint-down(md) {
      margin-top: 39px;
    }
  }
  &.layout-1 {
    .teaser-item {
      &.t1,
      &.t4 {
        grid-column-start: span 2;
        grid-column-end: span 2;
        width: 100%;
        .text-wrap {
          max-width: 75%;
          @include media-breakpoint-down(md) {
            max-width: 100%;
          }
        }
        margin-bottom: 16.25rem;
      }
      &.t2 {
        margin-bottom: 1.25rem;
      }
      &.t3 {
        margin-top: -10.625rem;
        margin-bottom: 17rem;
      }
      &.t4 {
        margin-bottom: 4rem;
      }
      &.t5 {
        margin-top: -14.0625rem;
        margin-bottom: 2.8125rem;
      }
      &.t6 {
        margin-top: 15.625rem;
        margin-bottom: 2.8125rem;
      }
      &.t7 {
        margin-bottom: 2.8125rem;
      }
    }

    .ratio-t1:after {
      padding-bottom: 54%;
    }
    .ratio-t2:after {
      padding-bottom: 133.34%;
    }
    .ratio-t3 {
      &:after {
        padding-bottom: 100%;
      }
    }
    .ratio-t4:after {
      padding-bottom: 54%;
    }
    .ratio-t5 {
      &:after {
        padding-bottom: 133.34%;
      }
    }
    .ratio-t6:after {
      padding-bottom: 100%;
    }
    .ratio-t7:after {
      padding-bottom: 100%;
    }
  }
  &.layout-2 {
    .teaser-item {
      &.t2,
      &.t6 {
        grid-column-start: span 2;
        grid-column-end: span 2;
        width: 100%;
        .text-wrap {
          max-width: 75%;
          @include media-breakpoint-down(md) {
            max-width: 100%;
          }
        }
        margin-bottom: 18.75rem;
      }
      &.t1 {
        margin-bottom: 2.1875rem;
      }
      &.t3 {
        grid-column-start: span 1;
        grid-column-end: span 1;
        width: 100%;
        top: 0;
        margin-bottom: 15rem;
      }
      &.t4 {
        margin-top: 15.625rem;
        margin-bottom: 15rem;
      }
      &.t5 {
        grid-column-start: span 1;
        grid-column-end: span 1;
        width: 100%;
        margin-top: -14.0625rem;
        margin-bottom: 0.9375rem;
        padding-left: 0;
      }
      &.t6 {
        top: 0;
        margin-bottom: 7.5rem;
      }
      &.t7 {
        margin-top: -11.25rem;
        margin-bottom: -3.75rem;
      }
    }
    .row {
      justify-content: end;
    }

    .ratio-t1:after {
      padding-bottom: 133.34%;
    }
    .ratio-t2:after {
      padding-bottom: 54%;
    }
    .ratio-t4:after {
      padding-bottom: 100%;
    }
    .ratio-t3:after {
      padding-bottom: 100%;
    }
    .ratio-t5:after {
      padding-bottom: 133.34%;
    }
    .ratio-t6:after {
      padding-bottom: 54%;
    }
    .ratio-t7:after {
      padding-bottom: 100%;
    }
  }
}
