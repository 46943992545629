.tx-storen-reference {
  .card{
    border: 0;
    h2 {
      line-height: 3rem;
    }
    img {
      transition: transform .3s ease;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    .ratio-teaser {
      &:after {
        padding-bottom: 73%;
      }
    }
  }
}

.storenreference_detail {
  .reference-images {
    margin-bottom: 5.5625rem;
    .image {
      margin-bottom: 2.25rem;
      overflow: hidden;
      img {
        transition: transform .3s ease;
      }
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
}
