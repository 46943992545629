.video-element {


  .col-md-4 {
    .video-box {
      min-height: 120px;
    }
  }
  .col-md-6 {
    .video-box {
      min-height: 75px;
    }
  }

  .col-md-12 {
    .title {
      max-width: 1200px;
      margin: 0 auto;
    }
    .description {
      max-width: 1200px;
      margin: 0 auto;
    }

  }

  .video-element-item {
    position: relative;
    overflow: hidden;

    .video-box.behind {
      opacity: 0;
    }
    .embed-thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
    }
  }
  .title {
    text-align: left;
    font: normal normal 300 46px/48px Aeonik-Regular;
    letter-spacing: 0px;
    color: $blue;
    opacity: 1;
  }
  .description {
    text-align: left;
    font: normal normal normal 18px/30px Aeonik-Regular;
    letter-spacing: 0.18px;
    color: $gray-900;
    opacity: 1;
  }
}
.container-full .video-element {

  .title {
    padding: 0 20px;
  }
  .description {
    padding: 0 20px;
  }
}

.v2 .container-narrow>.content-fluid.video-element {
  max-width: $extended-width-content-wide;
}

.video-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,.5);
}
.click.paused + .video-overlay,
.preview.paused + .video-overlay {
  display: flex;
}
