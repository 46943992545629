$header-height : 184px;

.v2 .headerelement {
  margin-left: -40px;
  margin-right: -40px;
}
.v2.heder-mode-dark .main-header,
.v2.heder-mode-light .main-header {

  #mainnavigation a.nav-link.level-top {
    text-align: left;
    font: normal normal 300 20px/20px Aeonik-Regular;
    letter-spacing: 0px;
    /*color: $white;*/
    opacity: 1;
  }
  .frame-space-after-small {
    padding-bottom: 5px!important;
    padding-top: 30px;
  }
  .breadcrumb-wrap:after {
    display: none;
  }
}
/*.v2.heder-mode-light .main-header #mainnavigation a.nav-link.level-top {
   text-shadow: 1px 1px 3px $black;
}
.v2.heder-mode-light .main-header #mainnavigation a.nav-link.level-top.collapsed .fal {
   text-shadow: -1px -1px 3px $black;
}
.v2.heder-mode-light .main-header .topbar .metanavigation a.nav-link.level-top {
  color: $white;
}*/

.v2.heder-mode-light .main-header .breadcrumb-item a {
  color: $white;
  text-shadow: 1px 1px 3px $black;
}
.v2.heder-mode-dark .main-header #mainnavigation a.nav-link.level-top {
  color: $gray-900;
}

.v2.heder-mode-dark.subenu-opened .main-header,
.v2.heder-mode-light.subenu-opened .main-header,
.v2.heder-mode-dark .main-header:hover,
.v2.heder-mode-light .main-header:hover {
  /*background-color: $white;*/
  .nav-container {
    background: rgba($white,1);
    &.nav-redesign {
      background: rgba($white,0);
    }
  }
  .topbar {
    /*background-color: $gray-400;*/
    .metanavigation a.nav-link.level-top {
      color: $gray-900;
    }
  }

  #mainnavigation a.nav-link.level-top {
    text-shadow: unset;
    color: $gray-900;

    &:hover {
      color:$blue;
      font: normal normal normal 20px/20px Aeonik-Regular;
      &:after {
        border-color: $blue;
      }
    }
    .fal {
      text-shadow: unset;
    }
  }


  .breadcrumb-item a {
    color: $blue;
    text-shadow: unset;
  }
}

.v2.heder-mode-dark.subenu-opened .main-header,
.v2.heder-mode-light.subenu-opened .main-header {
  .nav-container {
    background: rgba($white,1);
    &.nav-redesign {
      background: rgba($white,0.90);
    }
  }
}

.heder-mode-dark {

  .main-header {
    .nav-container {
      background: rgba($white,0);
    }
    /*.topbar {
      background-color: unset;
      min-height: 1rem;
    }*/
  }


  .headerelement {
/*min-height: calc(100vh - #{$header-height});*/

    .title {
      color: $blue;
      text-shadow: 1px 1px 3px #00000029;
/*      top: $header-height;*/
    }
    .subtitle {
      color:$gray-600;
      text-shadow: 1px 1px 3px #00000029;
/*      top: calc(50px + #{$header-height});*/
    }
  }
}

.heder-mode-light {

  .main-header {
    .nav-container {
      background-color: unset;
    }
    /*.topbar {
      background-color: unset;
      min-height: 1rem;
    }*/
  }

  .headerelement {

    .title {
      color: $white;
      text-shadow: 1px 1px 3px $black;
    }
    .subtitle {
      color: $white;
      text-shadow: 1px 1px 3px $black;
    }
  }
  //.hamburger-inner,
  //.hamburger-inner::after,
  //.hamburger-inner::before {
  //  color: $white;
  //  border-top: 1px solid $white;
  //  box-shadow: 1px 1px 3px $black;
  //}

  .is-active .hamburger-inner,
  .is-active .hamburger-inner::after,
  .is-active .hamburger-inner::before {
    color: inherit;
    border-top: unset;
    box-shadow: unset;
  }
}

.headerelement {
  position: relative;
  height: 100vh;
  overflow: hidden;
  margin-top: calc(-1* #{$header-height});
  min-height: 100vh;

  .headerelement-wrapper {
    position: absolute;
    width: calc(100vh * (1920 / 950));
    height: calc(100vw * (950 / 1920));
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .headerelement-item {
    width: 100%;
    height: auto;

    &.headerelement-item-video {

    }
    &.headerelement-item-image {

    }
  }
  .title-subtitle {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(70px + #{$header-height});
    .title {
      font: normal normal 300 60px/65px Aeonik-Regular;
      letter-spacing: 0px;
      opacity: 1;
    }
    .subtitle {
      font: normal normal 300 46px/48px Aeonik-Regular;
      letter-spacing: 0px;
      opacity: 1;
    }
    &.middle {
      top: 55%;
      transform: translateY(-55%);
    }
    &.bottom {
      top: auto;
      bottom: 45px;
    }
  }
  &.redesign {
    margin-top: 0;
    .navigation-wrap {
      @media (min-width: 1350px) {
        max-width: 1350px;
      }
      @media (max-width: 1350px) {
        max-width: 800px;
      }
      @include media-breakpoint-down(md) {
        max-width: calc(100vw - 100px);
      }
    }
    .title-subtitle {
      .title {
        font-family: Aeonik-Medium;
        font-size: 300px;
        font-weight: 600;
        line-height: 1;
        text-shadow: none;
        @media (max-width: 1350px) {
          font-size: 160px;
        }
        @include media-breakpoint-down(md) {
          font-size: 70px;
          letter-spacing: normal;
        }
      }
      .subtitle {
        font-size: 70px;
        font-weight: 600;
        line-height: 1;
        text-shadow: none;
        margin-left: 12px;
        @media (max-width: 1350px) {
          font-size: 50px;
          margin-left: 6px;
        }
        @include media-breakpoint-down(md) {
          font-size: 22px;
          letter-spacing: normal;
          margin-left: 0;
        }
      }
      .text {
        font-size: 35px;
        line-height: 40px;
        font-weight: normal;
        color: $white;
        margin-top: 31px;
        @media (max-width: 1350px) {
          font-size: 20px;
          line-height: 20px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 20px;
          line-height: 20px;
        }
        @include media-breakpoint-down(md) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .btn {
        margin-top: 80px;
        @media (max-width: 1350px) {
          font-size: 20px;
          padding-top: 18px;
          padding-bottom: 17px;
          margin-top: 30px;
        }
        @include media-breakpoint-down(md) {
          font-size: 18px;
          padding-top: 19px;
          padding-bottom: 18px;
        }
      }
    }
  }
}

.a-header {
  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
    margin-right: 1rem;
  }
  &.letter {
    span {
      display: inline;
      margin-right: 0;
    }
  }
  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
}
