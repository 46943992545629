.filmcinemascope,
.teaserpattern {
  .item-wrapper {
    display: block;
    img {
      transition: transform .3s ease;
    }
    &:hover {
      color: $body-color;
      text-decoration: none;
      img {
        transform: scale(1.2);
      }
      header:after {
        width: 100px;
      }
    }
  }
  .subheader {
    font-size: 0.9375rem;
    margin-bottom: 0;
    letter-spacing: 2.25px;
    text-transform: uppercase;
    margin-top: .75rem;
    font-family: 'Aeonik-Regular';
    color: $body-color;
    line-height: 1.81rem;
  }
  h3 {
    font-family: 'Aeonik-Regular';
    color: $body-color;
    margin-bottom: 1.5rem !important;
    word-break: break-word;
  }
  header {
    &:after {
      transition: width .35s ease-in-out;
      display: block;
      content: "";
      height: 1px;
      background: #707070;
      width: 80px;
    }
  }
  .teaser-item {
    grid-row-start: span 1;
    grid-row-end: span 1;
    grid-column-start: span 1;
    grid-column-end: span 1;
    top: 0;
    margin-bottom: 10rem;
  }
  .row {
    &.d-md-grid {
      @include media-breakpoint-up(lg) {
        display: grid;
        grid-auto-columns: 1fr;
        grid-column-gap: 36px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto auto;
        margin: 0;
        .col-auto {
          padding: 0;
        }
      }
    }
    @include media-breakpoint-down(md) {
      .col-auto {
        flex-basis: 50%;
        max-width: 50%;
        margin-bottom: 3.55rem!important;
        top: 0 !important;
      }
    }
  }
  &.layout-1 {
    .teaser-item {
      &.t1,
      &.t4 {
        @include media-breakpoint-down(md) {
          flex-basis: 100%;
          max-width: 100%;
        }
        grid-column-start: span 2;
        grid-column-end: span 2;
        width: 100%;
        .text-wrap {
          max-width: 75%;
          @include media-breakpoint-down(md) {
            max-width: 100%;
          }
        }
        margin-bottom: 18.4375rem;
      }
      &.t3 {
        top: -11.25rem;
        margin-bottom: 10.625rem;
      }
      &.t4 {
        margin-bottom: 9.375rem;
      }
      &.t5 {
        top: -15.3125rem;
        margin-bottom: -5.3125rem;
      }
      &.t6 {
        grid-column-start: span 2;
        grid-column-end: span 2;
        width: 75%;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
        margin-bottom: 6.25rem;
      }
    }
    .ratio-t1:after {
      padding-bottom: 54%;
    }
    .ratio-t2:after {
      padding-bottom: 133.34%;
    }
    .ratio-t3 {
      &:after {
        padding-bottom: 100%;
      }
    }
    .ratio-t4:after {
      padding-bottom: 54%;
    }
    .ratio-t5 {
      &:after {
        padding-bottom: 133.34%;
      }
    }
    .ratio-t6:after {
      padding-bottom: 62.53%;
    }
  }
  &.layout-2 {
    .teaser-item {
      &.t2,
      &.t3 {
        @include media-breakpoint-down(md) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &.t2,
      &.t3 {
        grid-column-start: span 2;
        grid-column-end: span 2;
        width: 100%;
        .text-wrap {
          max-width: 75%;
          @include media-breakpoint-down(md) {
            max-width: 100%;
          }
        }
        margin-bottom: 18.4375rem;
      }
      &.t1 {
        @include media-breakpoint-down(md) {
          order: 3;
        }
      }
      &.t2 {
        @include media-breakpoint-down(md) {
          order: 1;
        }
      }
      &.t3 {
        margin-bottom: 9.375rem;
        @include media-breakpoint-down(md) {
          order: 4;
        }
      }
      &.t4 {
        position: relative;
        top: -11.25rem;
        margin-bottom: 10.625rem;
        @include media-breakpoint-down(md) {
          order: 2;
        }
      }
      &.t5 {
        grid-column-start: span 2;
        grid-column-end: span 2;
        padding-left: 25%;
        @include media-breakpoint-down(md) {
          padding-left: 9px;
          order: 5;
        }
        margin-bottom: 6.25rem;
      }
      &.t6 {
        position: relative;
        top: -15.3125rem;
        margin-bottom: -15.3125rem;
        @include media-breakpoint-down(md) {
          order: 6;
        }
      }
    }
    .row {
      justify-content: end;
    }
    .ratio-t1:after {
      padding-bottom: 133.34%;
    }
    .ratio-t2:after {
      padding-bottom: 54%;
    }
    .ratio-t3:after {
      padding-bottom: 54%;
    }
    .ratio-t4:after {
      padding-bottom: 100%;
    }
    .ratio-t5:after {
      padding-bottom: 62.53%;
    }
    .ratio-t6:after {
      padding-bottom: 133.34%;
    }
  }
}
