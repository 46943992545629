.filmcinemascope {
  margin-bottom: 7.8125rem !important;
  @include media-breakpoint-down(md) {
    margin-bottom: 3.55rem!important;
  }
  .ratio-filmcinema {
    &:after {
      padding-bottom: 42.59%;
      @include media-breakpoint-down(md) {
        padding-bottom: 149.85%;
      }
    }
  }
  h3,
  .subheader {
    color: $white;
  }
  .subheader {
    font-size: 1.1875rem;
    @include media-breakpoint-down(md) {
      font-size: 1.15rem!important;
    }
  }
  h3 {
    font-size: 3.625rem;
    @include media-breakpoint-down(md) {
      font-size: 2.53rem!important;
    }
  }
  header {
    color: $white;
    top: 0;
    left: 0;
    padding-top: 3.9375rem;
    &:after {
      background: $white;
    }
    &.filter {
      background: rgba(0,0,0,.31);
    }
    &.dark {
      h3,
      .subheader {
        color: $gray-900 !important;
      }
      &:after {
        background: $gray-900 !important;
      }
    }
    &.video {
      text-shadow: 1px 1px 10px $gray-900;
      @include media-breakpoint-down(md) {
        position: relative !important;
        padding-top: .75rem !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-shadow: unset !important;
        h4 {
          margin-bottom: 0 !important;
          font-size: .9375rem !important;
        }
        h3 {
          font-size: 1.6875rem !important;
        }
        h3,
        .subheader {
          color: $gray-900 !important;
        }
        &:after {
          background: $gray-900 !important;
        }
      }
    }
  }
}
