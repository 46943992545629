.parallax-window, .parallax-slider {

  background: transparent;
  height: 790px;

  h2 {
    font-size: 3.125rem;
    @include media-breakpoint-down(md) {
      font-size: $font-size-base * 2.5;
    }
    @include media-breakpoint-down(sm) {
      font-size: $font-size-base * 1.875;
    }
  }

  @include media-breakpoint-down(md) {
    height: 300px;
  }

  .image {
    background-attachment: fixed;
    background-size: cover;
    //background-repeat: no-repeat;
    width: 100%;
    height: 100%;

    @include media-breakpoint-down(md) {
      height: auto;
    }

    position: absolute;
    z-index: 500;
  }
}

.imageHolder {
  width: 100vw;
  .img-holder-img {
    width: 100vw;
    z-index: 0 !important;
  }
  .stuff {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    width: 100vw;
    justify-content: center;
    text-align: center;
    z-index: 10000;
    color: #fff;
    margin: 0 auto;
    position: relative;

    .headline {
      font-family: $font-family-light;
      font-size: 3.125rem;
      line-height: 3.625rem;
      @include media-breakpoint-down(md) {
        h1,h2,h3,p {
          font-size: 34px !important;
        }
      }

      h1,h2,h3 {
        font-family: $font-family-medium;
        color: $white;
        margin-bottom: 0;
      }
      h2 {
        font-size: 3.125rem;
        @include media-breakpoint-down(md) {
          font-size: $font-size-base * 2.5;
        }
        @include media-breakpoint-down(sm) {
          font-size: $font-size-base * 1.875;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .button, .button-light {
      display: inline-block;
      .svg-icon {
        width: 3.12rem;
        height: 3.12rem;
        --color1: #ffffff;
        --color2: transparent;
        --color3: rgba(255,255,255,.15);
        @include media-breakpoint-down(md) {
          width: 3.52rem;
          height: 3.52rem;
        }
      }
      &:hover {
        .svg-icon {
          --color2: #ffffff;
          --color3: rgba(255,255,255,0);
        }
      }
      line-height: 2.25rem;
      margin: 0 auto;
      color: $white;
    }
    .button-dark {
      display: inline-block;
      .svg-icon {
        width: 3.12rem;
        height: 3.12rem;
        --color1: #333;
        --color2: transparent;
        --color3: rgba(51,51,51,.15);
        @include media-breakpoint-down(md) {
          width: 3.52rem;
          height: 3.52rem;
        }
      }
      &:hover {
        .svg-icon {
          --color2: #333;
          --color3: rgba(51,51,51,0);
        }
      }
      line-height: 2.25rem;
      margin: 0 auto;
      color: $white;
    }

  }
}

.fullimage-ratio {
  &:after {
    padding-bottom: 41.14% !important;
  }
  @include media-breakpoint-down(md) {
    &:after {
      padding-bottom: 100% !important;
    }
  }
}

