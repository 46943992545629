.tx-storen-location {
  .locWrapper {
    height: 41vw;
    @include media-breakpoint-down(md) {
      height: initial;
    }
    .loc-item {
      line-height: 21px;
      border: 0;
      border-bottom: 1px solid $gray-600;
      cursor: pointer;
      .loc-text {
        display: flex;
        flex-direction: column;
      }
      .icons {
        min-width: 60px;
        .catIcon {
          margin-top: -8px;
          &:nth-child(1){
            margin-left: -14px;
          }
          &:nth-child(n+2){
            margin-left: -40px;
          }
        }
      }
      &.hover,
      &:hover {
        color: $cyan;
        .btn {
          background-color: $white;
          border: 1px solid $gray-900;
        }
      }
      &.active {
        color: $blue;
        .btn {
          display: none;
        }
      }
    }
  }
  .mapWrapper {
    height: 41vw;
    @include media-breakpoint-down(md) {
      height: 160vw;
      padding: 30px 19px 30px 19px;
    }

    #locationMap {
      width: 50vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include media-breakpoint-down(md) {
        width: 100vw;
        margin-left: -11px;
      }
    }
    #mapOverlay {
      display: none;
      width: 50vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255,255,255,.85);
      z-index: 5;
      @include media-breakpoint-down(md) {
        width: 100%;
        background: none;
      }
    }
    .mapDetail {
      padding: 3.4375rem;
      display: none;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      .headerPart {
        margin-bottom: 1.875rem;
        .mapHead {
          font-size: 1.5rem;
          line-height: 1.75rem;
          color: $blue;
          .detailClose {
            position: absolute;
            right: 0;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            .svg-icon {
              width: 1.7rem;
              height: 1.7rem;
              color: #434c53;
              --color1: #434c53;
            }
            @include media-breakpoint-down(md) {
              top: 0;
              right: 0;
              -ms-transform: initial;
              transform: initial;
            }
          }
        }
      }
      .infoPart {
        margin-bottom: 4rem;
        .buttons {
          @include media-breakpoint-down(md) {
            margin-top: 1.25rem;
          }
        }
      }
      @include media-breakpoint-down(md) {
        background: rgba(255,255,255,1);
        padding: 1.25rem;
        position: relative !important;
      }
    }
  }
  form {
    #locSearch {
      &.active {
        z-index: 1001;
        position: relative;
        border: 0;
        text-indent: 20px;
      }
    }
    .form-label {
      color: $gray-900;
      border-top: $input-border-width solid $gray-900;
    }
    input {
      &:focus,
      &.form-control {
        border-color: $gray-900 !important;
      }
    }
    .searchIcon {
      position: absolute;
      right: 0;
      top: 25%;
    }
    .filterCats {
      .filterCatIcon {
        margin-top: -10px;
        margin-left: -14px;
      }
      .custom-checkbox {
        padding-right: 30px;
      }
      .custom-control-label {
        padding-left: 2.5rem;
        &:before,
        &:after {
          left: 0;
        }
      }
    }
    .typeahead {
      &.dropdown-menu {
        box-shadow: 0 0 20px #00000029;
        padding-top: 65px;
        top: 0 !important;
        width: 100%;
        border: 0;
        margin: 0;
        li {
          border-bottom: 1px solid #6d7579;
          padding-top: 9.5px;
          padding-bottom: 9.5px;
          margin: 0 20px;
          &:last-child {
            border: 0;
          }
          a {
            color: $gray-900;
            padding: 0;
            &:hover {
              color: $cyan;
              background: none;
            }
          }
        }
      }
    }
  }
  .noLocation {
    font-family: $font-family-light;
    font-size: 1.5625rem;
    line-height: 2rem;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
