.productintroduction {
  @include media-breakpoint-down(md) {
    h1 {
      margin-bottom: 0.25rem !important;
      line-height: 3rem !important;
    }
    .lead {
      line-height: 31px;
    }
  }
}
