#download-detail,
.tx-storen-download {
  .ratio-download {
    &:after {
      padding-bottom: 70.5%;
    }
  }
  .modal-dialog,
  .download-list {
    .download-item {
      .btn.cart {
        @include media-breakpoint-down(md) {
          padding: 0.5rem !important;
          border: 0 !important;
        }
      }
      .title {
        word-break: break-word;
      }
      .list-format {
        font-weight: bold;
        color: $blue;
        font-size: 0.75rem
      }
    }
  }
}

.active-sticky {
  .hide-on-sticky {
    display: none !important;
  }
}

.download-cart-list {
  .btn.cart {
    @include media-breakpoint-down(md) {
      width: initial !important;
      padding: 0 !important;
    }
    @include media-breakpoint-down(sm) {
      .svg-icon {
        margin-right: -5px;
      }
    }
  }
}

#download-detail {
  .modal-dialog {
    width: 90%;
    max-width: 1400px;
    @include media-breakpoint-down(sm) {
      width: 98%;
      max-width: 98%;
      .modal-body {
        padding: 1.25rem;
      }
    }
    .close {
      .svg-icon {
        width: 35px;
        height: 35px;
      }
    }
  }
}

html.magnifying > body {
  overflow-x: hidden !important;
}
.magnify,
.magnify > .magnify-lens,
.magnify-mobile,
.lens-mobile { /* Reset */
  min-width: 0;
  min-height: 0;
  animation: none;
  border: none;
  float: none;
  margin: 0;
  opacity: 1;
  outline: none;
  overflow: visible;
  padding: 0;
  text-indent: 0;
  transform: none;
  transition: none;
}
.magnify {
  position: relative;
  width: auto;
  height: auto;
  box-shadow: none;
  display: inline-block;
  z-index: inherit;
}
.magnify > .magnify-lens { /* Magnifying lens */
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  /* Multiple box shadows to achieve the glass lens effect */
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85),
  0 0 7px 7px rgba(0, 0, 0, 0.25),
  inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  cursor: none;
  display: none;
  z-index: 99;
}
.magnify > .magnify-lens.loading {
  background: #333 !important;
  opacity: 0.8;
}
.magnify > .magnify-lens.loading:after { /* Loading text */
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #fff;
  content: 'Loading...';
  font: italic normal 16px/1 Calibri, sans-serif;
  letter-spacing: 1px;
  margin-top: -8px;
  text-align: center;
  text-shadow: 0 0 2px rgba(51, 51, 51, 0.8);
  text-transform: none;
}

.download-filters {
  .product-filter-item {
    margin-top: 10px;
    padding: 5px 10px;
    .filter-download-title {
      .fal {
        font-size: 1.4rem;
        transition: $transition-base;
      }
      &:not(.collapsed){
        font-style: italic;
        .fal {
          transform: rotate(180deg);
        }
      }
    }
  }
}
